import Vue from 'vue'

const actions = {
  SHOW_MESSAGE (context, payload) {
    return Vue.$vToastify[payload.type](
      {
        duration: payload.duration ?? null,
        title: payload.title,
        body: payload.body,
        theme: payload.type
      });
  }
}

export default {
  namespaced: true,
  actions
}
