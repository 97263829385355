let state = {
    extraFees: localStorage.getItem('extraFees') ? JSON.parse(localStorage.getItem('extraFees')) : null,
    minOrderAmount: localStorage.getItem('minOrderAmount') ? parseFloat(JSON.parse(localStorage.getItem('minOrderAmount'))) : null,
};

const actions = {

};

const mutations = {
    REMOVE_EXTRA_FEES (state) {
        state.extraFees = null;
        state.minOrderAmount = null;
    },
    SET_EXTRA_FEES (state, payload) {
        localStorage.setItem('extraFees', JSON.stringify(payload));
        state.extraFees = payload
    },
    SET_MIN_ORDER_AMOUNT (state, payload) {
        if (payload === undefined) {
            payload = 0
        }
        localStorage.setItem('minOrderAmount', JSON.stringify(payload));
        state.minOrderAmount = parseFloat(payload)
    }
};

const getters = {
    GET_EXTRA_FEES: state => state.extraFees,
    MIN_ORDER_AMOUNT: state => state.minOrderAmount,
    MIN_ORDER_FEE_WITH_VAT: state => {
        let minOrderFee = 0;
        if (state.extraFees) {
            state.extraFees.forEach(extraFee => {
                if (extraFee.min_order_amount) {
                    minOrderFee = parseFloat(extraFee.price_incl_tax)
                }
            });
        }
        return minOrderFee
    },
    MIN_ORDER_FEE_WITH_OUT_VAT: state => {
        let minOrderFee = 0;
        if (state.extraFees) {
            state.extraFees.forEach(extraFee => {
                if (extraFee.min_order_amount) {
                    minOrderFee = parseFloat(extraFee.price_excl_tax)
                }
            });
        }
        return minOrderFee
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
