import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login'
import axios from 'axios'
import categories from './views/shop/Categories'
import categoriesBeta from './views/shop/CategoriesBeta'
import store from './store/store'

Vue.use(Router);

// this is used to login validation, prevent to redirect to shop page with old token
const validToken = (to, from, next) => {
  const token = localStorage.getItem('access_token');
  axios.post(process.env.VUE_APP_BACKEND_URL + '/api/auth/me', {}, {headers: {'Authorization': token, 'Accept': 'application/json'}})
    .then(function(){
      if (Router.currentRoute.path === '/maintenance') {
        next();
      } else {
        next('/shop');
      }
    })
    .catch(() => {
      next();
    });
};


export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/maintenance',
      name: 'maintenance',
      meta: {
        requiresAuth: false
      },
      props: true,
      component: () => import('./views/Maintenance')
    },
    {
      path: '/',
      name: 'admin-login',
      component: Login,
      beforeEnter: function(to, from, next) {
        validToken(to, from, next)
      }
    },
    {
      path: '/admin',
      name: 'login',
      component: Login
    },
    {
      path: '/shop',
      name: 'shop',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/shop/Index')
    },
    {
      path: '/shop/categories',
      name: 'categories',
      meta: {
        requiresAuth: true
      },
      component: categories
    },
    {
      path: '/shop/categories-beta',
      name: 'categories-beta',
      meta: {
        requiresAuth: true
      },
      component: categoriesBeta
    },
    {
      path: '/order/templates',
      name: 'order-templates',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/order/OrderTemplates')
    },
    {
      path: '/shop/cart',
      name: 'cart',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/mobile/Cart')
    },
    {
      path: '/order-success',
      name: 'order-success',
      beforeEnter: function (to, from, next) {
        // if its redirect from prepayment
        if (from.path === '/') {
          store.dispatch('Order/SET_ORDER_STATUS', {}, { root:true })
            .then(() => next())
        } else {
          next()
        }
      },
      meta: {
        orderSuccess: true
      },
      component: () => import('./views/order/OrderSuccess')
    },
    {
      path: '/order/orders/:orderId?',
      name: 'orders',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/order/Orders')
    },
    {
      path: '/faq',
      name: 'faq',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/faq/Faqs')
    },
    {
      path: '/inventory',
      name: 'inventory',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/inventory/Inventory')
    },
    {
      path: '/prepayment/payment-types',
      name: 'prepayment',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/prepayment/Prepayment')
    },
    {
      path: '/reclamation',
      name: 'reclamation',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/reclamation/Reclamation')
    },
    {
      path: '/reclamation/list',
      name: 'reclamation-list',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/reclamation/ReclamationList')
    },
    {
      path: '/feedback',
      name: 'feedback',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/feedback/FeedbackForm')
    },
    {
      path: '*',
      name: 'page-not-found',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/PageNotFound')
    }
  ]
})
