<template>
  <div ref="cartNotEmptyModal" class="modal" id="modal-center" tabindex="-1" aria-labelledby="modal-center-label"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="row">
            <div class="col-12">
              <strong>{{ $t('cart.not_empty') }}</strong>
            </div>
            <div class="col-12 mt-1">
              <p>{{ $t('cart.not_empty_info') }}</p>
            </div>
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <button class="btn btn-secondary confirm-button" @click="closeOrderAgainModal()">{{ $t('button.cancel')
                  }}</button>
                <button class="btn btn-primary confirm-button ms-2" @click="emptyCart()">{{ $t('button.ok') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

    export default {
      data() {
        return {
            bsModal: null
        }
    },
    mounted() {
        // Initialize Bootstrap 5 Modal
        this.bsModal = new Modal(this.$refs.cartNotEmptyModal);
    },
        methods: {
            show () {
                this.bsModal.show();
            },
            closeOrderAgainModal () {
              this.bsModal.hide();
            },
            emptyCart () {
                this.$store.dispatch('Cart/REMOVE_CART')
                    .then(() => this.closeOrderAgainModal())
            }
        },
        computed: {
            //
        }
    }
</script>

<style scoped></style>
