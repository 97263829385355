<template>
    <div ref="emptyCartModal" class="modal" id="modal-center" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div class="row">
                        <div class="col-12 mb-1">
                            <strong>{{$t('modal.empty_cart')}}?</strong>
                        </div>
                        <div class="col-12">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btn-secondary confirm-button"
                                        @click="hide()">{{$t('button.cancel')}}</button>
                                <button type="button" class="btn btn-primary confirm-button ms-2 empty-cart"
                                        @click="emptyCart()">{{$t('button.ok')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { Modal } from 'bootstrap';
export default {
    props: {
        closeModal: {
            type: Function
        }
    },
    computed: {
        item () {
            return this.$store.getters['Cart/GET_REMOVE_ITEM']
        }
    },
    mounted() {
        // Create a new Modal instance with the ref from the template
        this.emptyCartModal = new Modal(this.$refs.emptyCartModal);
        this.emptyCartModal.show();
    },
    methods: {
        hide () {
            this.$store.commit('Modal/DISABLE_REMOVE_ITEM');
            this.emptyCartModal.hide();
            this.closeModal();
        },
        show () {
            this.emptyCartModal.show();
        },
        emptyCart () {
            this.$store.dispatch('Cart/REMOVE_CART');
            this.hide();
        }
    }
}
</script>

<style scoped></style>
