<template>
    <div>
        <div class="row m-0">
            <div class="col-12 shopping__cart-header pl-4 pl-md-3 text-nowrap">
                <span class="d-md-none" id="cart-toggle" @click="toggleCart">
                    <i class="fa fa-arrow-left me-3 text-primary"></i>
                </span>
                <span>
                    <cart-icon />
                </span>
                <span class="header ml-5">
                    {{shoppingCartHeader}}
                </span>
            </div>

            <div class="col-12" :class="['shopping__cart-item-container', 'px-4', 'py-2', {'empty-cart': isEmptyCart}]">

                <div class="row">
                    <div class="col-12">
                        <div v-if="isEmptyCart" class="text-nowrap">
                            <i>{{$t('cart.no_items')}}</i>
                        </div>

                        <div v-else-if="cartUpdating" class="text-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                        <div v-else>
                            <div class="d-flex align-content-center justify-content-end form-check form-switch">
                                <p class="small mb-0 me-5 h-100 info-switch">
                                    <span v-if="extraInfo">{{$t('cart.close_extra_info')}}</span>
                                    <span v-else>{{$t('cart.show_extra_info')}}</span>
                                </p>
                                <input type="checkbox" v-model="extraInfo" class="header form-check-input switch"
                                    role="switch" />
                            </div>

                            <div v-for="(sortedGroup, i) in sortedCart" :key="i"
                                v-show="(isMobile && !showOrderModal) || !isMobile">
                                <cart-group :extra-info="extraInfo" :sortedGroup="sortedGroup" :index="i" />
                            </div>
                            <div class="d-flex align-content-center justify-content-end mb-2">
                                <button @click="removeItems" class="btn btn-outline-secondary w-auto m-1">
                                    {{ $t('cart.empty_cart') }} <i class="ms-2 fa fa-trash-can"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" v-if="!isEmptyCart">
                        <totals :totals="cartTotals" :component-type="'cart'"></totals>
                    </div>
                </div>

                <div class="row" v-show="!isEmptyCart && !cartUpdating">
                    <div class="col-12 text-center mt-3" @click="toggleOpenSaveOrderTemplateNameModalOpen">
                        <p class="save-order-template" v-show="!amountsNotValid">{{$t('cart.save_order_template')}}</p>
                    </div>
                </div>

                <div class="row" v-show="!isEmptyCart && !showOrderModal">
                    <div class="col-12 text-center d-md-none" @click="toggleCart">
                        <p class="save-order-template">{{$t(continueOrderText)}}</p>
                    </div>
                </div>

            </div>
        </div>

        <!-- CONFIRM ORDER -->
        <confirm-order v-if="showOrderModal" @confirm-order="orderItems()" />

        <!-- SAVE ORDER TEMPLATE -->
        <order-template-name-modal v-if="openSaveOrderTemplateNameModalOpen" />

        <!-- EMPTY CART -->
        <empty-cart-modal v-if="showEmptyCartModal" :closeModal="closeEmptyCartModal" />
    </div>
</template>


<script>
    import CartGroup from './CartGroup'
    import Totals from '@/components/general/Totals'
    import CartIcon from '@/components/icons/cart/Cart'
    import CartMixins from '@/mixins/Cart'

    /* Modals */
    import ConfirmOrder from '@/components/modals/order/ConfirmOrder'
    import OrderTemplateNameModal from '@/components/modals/SaveOrderTemplate'
    import EmptyCartModal from "@/components/modals/EmptyCartModal.vue";

    export default {
        components: {
            ConfirmOrder,
            CartGroup,
            Totals,
            OrderTemplateNameModal,
            CartIcon,
            EmptyCartModal
        },
        mixins: [ CartMixins ],
        data () {
            return {
                openSaveOrderTemplateNameModalOpen: false,
                orderSuccess: false,
                sortedCart1: [],
                orderError: false,
                extraInfo: false,
                cartTotals2: {
                    withVat: 0,
                    withOutVat: 0
                },
                totals2: {
                    withVat: 0,
                    withOutVat: 0
                },
                showEmptyCartModal: false
            }
        },
        methods: {
            toggleOpenSaveOrderTemplateNameModalOpen () {
                this.openSaveOrderTemplateNameModalOpen = !this.openSaveOrderTemplateNameModalOpen
            },
            removeItems() {
                this.showEmptyCartModal = true
            },
            closeEmptyCartModal() {
                this.showEmptyCartModal = false
            },
        },
        computed: {
            amountsNotValid () {
                return this.$store.getters['Cart/ITEM_AMOUNTS_NOT_VALID']
            },
            showOrderModal () {
                return this.$store.getters['Modal/CONFIRM_ORDER']
            },
            isMobile() {
                return window.innerWidth <= 1023;
            },
        }
    }
</script>

<style scoped>
.header {
    margin-left: 0.5rem;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.shopping__cart-header {
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    height: 3rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: .25rem .25rem 0 0;
}

.shopping__cart-item-container {
    background-color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.shopping__cart-summary {
    background-color: white;
}

.empty-cart {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.info-switch {
    padding-top: 2px;
}

.switch {
    border-color: var(--bs-gray-500);
}

.save-order-template {
    cursor: pointer;
    text-decoration: underline;
}
</style>
