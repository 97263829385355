<template>
    <div ref="orderTemplateCartNotEmpty" class="modal" id="modal-center" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true">

        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body text-center py-4">
                    <div class="row">
                        <div class="col-12">
                            <strong>{{ $t('cart.not_empty') }}</strong>
                        </div>

                        <div class="col-12 mt-1">
                            <p>{{ $t('cart.not_empty_info') }}</p>
                        </div>

                        <div class="col-12">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn confirm-button foodstock-btn" @click="closeModal()">
                                    {{ $t('button.cancel') }}
                                </button>
                                <button type="button" class="btn btn-primary confirm-button ms-2 foodstock-btn"
                                        @click="emptyCartAndOrderFromTemplateAgain()">
                                    {{ $t('button.ok') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from 'bootstrap';

export default {
    props: {
        closeParentModal: {type: Function}
    },
    mounted() {
        this.orderTemplateCartNotEmpty = new Modal(this.$refs.orderTemplateCartNotEmpty)
        this.showModal()
    },
    methods: {
        closeModal() {
            this.orderTemplateCartNotEmpty.hide()
            this.closeParentModal()
        },
        showModal() {
            this.amount = 1
            this.orderTemplateCartNotEmpty.show()
        },
        emptyCartAndOrderFromTemplateAgain() {
            const vue = this
            this.$store.dispatch('Cart/REMOVE_CART');
            this.$store.dispatch('Order/ORDER_FROM_TEMPLATE')
                .then(() => {
                    this.$router.push({name: 'categories', params: {orderAgain: true}})
                })
                .finally(() => {
                    vue.closeModal()
                })
        }
    },
    computed: {
        itemsNotFoundOrderAgain() {
            return this.$store.getters['Order/GET_NOT_FOUND_ITEMS_ORDER_AGAIN']
        },
        cartIsNotEmpty() {
            return this.cartNotEmpty
        }
    }
}
</script>

<style scoped></style>
