let state = {
    items: []
};

const actions = {
    ADD_ITEM (context, payload){
        context.commit('ADD_ITEM', payload)
    }
};

const mutations = {
    ADD_ITEM (state, payload) {

        // if its first item to add
        if (state.items.length === 0) {
            state.items.push(payload)
        } else {
            state.items.forEach(item => {
                if (item.product_id !== payload.product.product_id) {
                    state.items.push(payload)
                }
            })
        }
    },
    REMOVE_ITEMS () {
        state.items = [];
    }
};

const getters = {
    ITEMS: state => state.items,
    ITEMS_NOT_FOUND: state => state.items.length > 0
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
