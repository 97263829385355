import _ from 'lodash'
import {ADDED_ITEM_ID_PREFIX} from "../../util/const";
import i18n from '../../../src/i18n'

let state = {
    inventoryCatalog: sessionStorage.getItem('inventoryCatalog') ? JSON.parse(sessionStorage.getItem('inventoryCatalog')) : null,
    inventoryItems: sessionStorage.getItem('inventoryItems') ? JSON.parse(sessionStorage.getItem('inventoryItems')) : [],
    addedItems: []
};

const actions = {
    INIT_CATALOG ({commit, rootGetters}) {
        let inventoryCatalog = {};
        let catalog = rootGetters['Catalog/GET_CATALOG'];
        Object.assign(inventoryCatalog, catalog);

        // Filter away products where 'enable_inventory' is not true
        inventoryCatalog.categories.forEach((sortedGroup, catIndex) => {
            inventoryCatalog.categories[catIndex].products =
                sortedGroup.products.filter((prod) => {
                    return prod.enable_inventory === 1
                });
        });

        // Filter away empty categories
        inventoryCatalog.categories = inventoryCatalog.categories.filter(category => {
            return category.products.length > 0
        });


        inventoryCatalog.categories.forEach(sortedGroup => {
            sortedGroup.products.map(product => {
                product.amount = 0
            })
        });

        commit('SET_CATALOG', inventoryCatalog)
    },
    ADD_TO_INVENTORY_LIST ({commit}, payload) {
        commit('ADD_TO_INVENTORY_LIST', payload)
    },
    ADD_ITEM ({commit}, payload) {
        if (payload.price_incl_tax.includes(',')) {
            payload.price_incl_tax = payload.price_incl_tax.replace(',', '.')
        }
        if (payload.price_excl_tax.includes(',')) {
            payload.price_excl_tax = payload.price_excl_tax.replace(',', '.')
        }
        if (payload.amount.includes(',')) {
            payload.amount = payload.amount.replace(',', '.');
        }
        payload.price_incl_tax = parseFloat(payload.price_incl_tax);
        payload.price_excl_tax = parseFloat(payload.price_excl_tax);
        payload.amount = parseFloat(payload.amount);

        commit('ADD_ITEM', payload)
    }
};

const mutations = {
    SET_CATALOG (state, payload) {
        state.inventoryCatalog = payload;
    },
    ADD_TO_INVENTORY_LIST (state, payload) {
        const isManuallyAdded = payload.id && payload.id.startsWith(ADDED_ITEM_ID_PREFIX);

        if (isManuallyAdded) {
            const index = state.addedItems.findIndex(item => item.id === payload.id);
            state.addedItems[index].amount = payload.amount;
            sessionStorage.setItem('addedItems', JSON.stringify(state.addedItems));
            return;
        }

        let items = [];
        const language = i18n.locale
        state.inventoryCatalog.categories.forEach(sortedGroup => {
            sortedGroup.products.forEach(product => {
                if (product.id === payload.id) {
                    // if amount is positive, add to items
                    if (payload.amount >= 0) {
                        // check if item is added to array
                        items = state.inventoryItems.filter(item => item.id === payload.id);
                        if (items.length > 0) {
                            let index = state.inventoryItems.findIndex(item => item.id === payload.id);
                            state.inventoryItems[index].amount = payload.amount
                        } else {
                            product.amount = payload.amount;
                            let inventoryItem = {
                                category: language === 'fi' ? product.category_description_fi : product.category_description_en,
                                id: payload.id,
                                description: language === 'fi' ? product.description_fi : product.description_en,
                                price_excl_tax: parseFloat(product.price_excl_tax),
                                price_incl_tax: parseFloat(product.price_incl_tax),
                                total_excl_tax: 0, // this is helper for excel to make formula on this cell
                                total_incl_tax: 0, // this is helper for excel to make formula on this cell
                                vat_percent: product.vat_percent,
                                amount: payload.amount,
                                retail_sales_unit_amount: product.retail_sales_unit_amount,
                                unit_of_measure: product.retail_sales_unit_of_measure || product.unit_of_measure,
                            };
                            state.inventoryItems.push(inventoryItem)
                        }
                    } else {
                        let index = 0;
                        for (let item of state.inventoryItems) {
                            if (item.id === payload.id) {
                                break;
                            }
                            index ++;
                        }
                        state.inventoryItems.splice(index, 1)
                    }
                }
            })
        });
        sessionStorage.setItem('inventoryCatalog', JSON.stringify(state.inventoryCatalog));
        sessionStorage.setItem('inventoryItems', JSON.stringify(state.inventoryItems));
    },
    ADD_ITEM (state, payload) {
        const id = `${ADDED_ITEM_ID_PREFIX}${state.addedItems.length}`;
        const itemWithId = {...payload, id: id };
        state.addedItems.push(itemWithId);
        sessionStorage.setItem('addedItems', JSON.stringify(state.addedItems));
    },
    REMOVE_INVENTORY_ITEMS (state) {
        sessionStorage.removeItem('inventoryItems');
        state.inventoryItems = []
    },
    REMOVE_ADDED_ITEMS (state) {
        state.addedItems = []
    }
};

const getters = {
    GET_CATALOG: state => state.inventoryCatalog,
    GET_TOTALS: state => {
        let totals = {
            price_incl_tax: 0,
            price_excl_tax: 0
        };

        state.inventoryItems.forEach(product => {
            const retail_sales_unit_amount = product.retail_sales_unit_amount || 1;

            totals.price_excl_tax += (product.amount / retail_sales_unit_amount) * product.price_excl_tax;
            totals.price_incl_tax += (product.amount / retail_sales_unit_amount) * product.price_incl_tax;
        });
        state.addedItems.forEach(product => {
            const retail_sales_unit_amount = product.retail_sales_unit_amount || 1;

            totals.price_excl_tax += (product.amount / retail_sales_unit_amount) * product.price_excl_tax;
            totals.price_incl_tax += (product.amount / retail_sales_unit_amount) * product.price_incl_tax;
        });
        return totals
    },
    GET_INVENTORY_ITEMS: state => {
        let originalItems = _.sortBy(state.inventoryItems, 'category', 'asc');
        if (state.addedItems.length > 0) {
            let addedItems = _.sortBy(state.addedItems, 'category', 'asc');
            return originalItems.concat(addedItems);
        } else {
            return originalItems
        }
    },
    GET_ADDED_ITEMS: state => _.sortBy(state.addedItems, 'description', 'asc')

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
