<template>
  <div>
    <div class="row">
      <div class="col">
        <form @submit="onSubmit">
          <div class="input-group mb-2">
            <span class="input-group-text"><i class="fa-solid fa-user"></i></span>
            <input id="email" :placeholder="$t('login.username')" class="border-left-0 foodstock-input form-control"
              type="text" v-model="form.username" required />
          </div>
          <div class="input-group mt-3">
            <span class="input-group-text"><i class="fa-solid fa-lock"></i></span>
            <input id="password" :placeholder="$t('login.password')" class="border-left-0 foodstock-input form-control"
              type="password" v-model="form.password" required />
          </div>
          <div class="modal" tabindex="-1" id="captchaModal">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ $t('login.captcha') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <vue-recaptcha ref="recaptcha" v-if="captchaKey" :sitekey="captchaKey" language="fi"
                    @verify="verifyCallback"></vue-recaptcha>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-3 px-0">
            <button type="submit" class="btn btn-primary foodstock-btn shadow px-4 w-100" :disabled="loading">
              {{$t('button.logIn')}}
              <span v-if="loading">
                <div class="spinner-border spinner-border-sm" role="status"><span
                    class="visually-hidden">Loading...</span></div>
              </span>
              <span v-else><span icon="fa fa-angle-right" class="ms-2"></span></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha';
import { Modal } from 'bootstrap';

export default {
  components: {
      VueRecaptcha
    },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      recaptchaResponse: null,
      captchaModal: null,
      captchaKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      loading: false
    }
  },
  mounted() {
    this.captchaModal = new Modal(document.getElementById('captchaModal'), {
      keyboard: false,
    });
  },
  methods: {
    verifyCallback(response) {
      this.recaptchaResponse = response;  // Save the reCAPTCHA response
      this.captchaModal.hide(); // close the modal
      // Proceed with the login
      this.loading = true;
      const { form } = this;
      this.$store.dispatch('Auth/LOGIN', form)
          .then((data) => {
            if (!data) {
              setTimeout(() => this.$refs.recaptcha.reset(), 500);
              this.loading = false;
            }
          });
    },
    onSubmit(e) {
      e.preventDefault();
      this.captchaModal.show(); // display the modal
    },
  }
}
</script>
<style scoped lang="scss">
.input-group-text {
  background: none;
}

.foodstock-input {
  height: 3rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 1em;
  width: auto; // or the exact width of reCAPTCHA if you know it
  min-width: 300px; // if you still want to ensure a minimum width
  text-align: center;
}

.modal-content .g-recaptcha {
  transform: scale(0.9);
  transform-origin: 0 0;
}

.modal-close {
  float: right;
  cursor: pointer;
}
</style>