<template>
  <div>
    <div v-if="searchResults && searchResults.length > 0">
      <p v-for="(result, i) in searchResults" :key="i" class="result">
        <span @click="selectSearchResult(result.id)" class="result__description">{{ result[`description_${$i18n.locale}`] }}</span>
      </p>
    </div>
    <div v-else>
      <div v-if="searchActive" class="my-2">
        <i class="text-muted ms-2">{{ $t('search.no_results') }}</i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['searchKey'],
  computed: {
    searchActive () {
      return this.searchKey.length >= 3
    },
    searchResults () {
      // Additional checks so that this doesnt return undefined error
      let products = this.$store.getters['Catalog/GET_PRODUCTS'];
      // const locale = this.$i18n.locale
      if (this.searchKey && this.searchKey.length >= 3) {
        return products.filter(product => {
          let validDescription = product[`description_${this.$i18n.locale}`] && typeof product[`description_${this.$i18n.locale}`] === 'string';
          let validId = product.id !== null && product.id !== undefined;

          if (validDescription && validId) {
            return product[`description_${this.$i18n.locale}`].toLowerCase().includes(this.searchKey.toLowerCase().trim())
              || product.id.toString().toLowerCase().includes(this.searchKey.trim());
          }
          return false;
        });
      } else {
        return null;
      }
    },
    products () {
      return this.$store.getters['Catalog/GET_PRODUCTS']
    }
  },
  methods: {
    selectSearchResult (productId) {
      let products = this.products;
      let item = products.filter(product => product.id === productId);
      this.$store.commit('Catalog/SET_SELECTED_ITEM', item[0]);
      this.$store.commit('Search/TOGGLE_SEARCH');

      if (!this.$isCategoryPage) {
        this.$router.push({ name: 'categories', params: { search_result: true } })
      } else {
        this.$store.commit('Modal/ACTIVATE_SELECTED_ITEM');
      }
    }
  }
}
</script>
<style scoped lang="scss">
.result {
  margin: 0;
  cursor: pointer;
  height: 2rem;
  display: flex;
  align-items: center;

  &__description {
    padding: 0 .5rem 0 .5rem;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  &:hover {
    background-color: rgba(0,0,0, .03);
  }
}
</style>
