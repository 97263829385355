let state = {
    autoAddProductAdded: false,
    locations: ['AMBIENT', 'CHILLED', 'FROZEN'],
    products: []
};

const actions = {
    //
};

const mutations = {
    ADD_AUTO_ADD_PRODUCTS (state, payload) {
        let autoAddProducts = payload;
        state.products = [];

        autoAddProducts.forEach(autoAddProduct => {

            // if item is with orderFromTemplate flag, and its found in cart, dont trigger notification
            if (autoAddProduct.showAutoAddProductNotification) {
                state.autoAddProductAdded = true;
            }
            state.products.push(autoAddProduct)
        })
    },
    DISABLE_NOTIFICATION (state) {
        state.autoAddProductAdded = false;
        state.products = []
    }
};

const getters = {
    GET_PRODUCTS: state => state.products,
    AUTO_ADD_PRODUCT_ADDED: state => state.autoAddProductAdded,
    GET_LOCATIONS: state => state.locations
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
