<template>
  <div class="row contact-info">
    <div class="col-12 col-lg-6 phone">
      <a :class="{'text-white': color === 'white'}" href="tel:+358207716550">
        <span class="fa-stack">
          <i class="fa-regular fa-circle fa-stack-1x"></i>
          <i class="fa-solid fa-phone fa-flip-horizontal fa-stack-1x"></i>
        </span>
        <span class="info-row">{{$t('form.call_customer_service')}}</span>
      </a>
    </div>
    <div class="col-12 col-lg-6 email">
      <a :class="{'text-white': color === 'white'}" href="mailto:info@foodstock.fi">
        <span class="fa-stack">
          <i class="fa-regular fa-circle fa-stack-1x"></i>
          <i class="fa fa-envelope fa-flip-horizontal fa-stack-1x"></i>
        </span>
        <span class="info-row">{{$t('form.send_mail_customer_service')}}</span>
      </a>
    </div>
  </div>
</template>

<script>

export default {
    props: {
        color: String
    }
}
</script>

<style scoped lang="scss">
.contact-info {
  margin: 1.5em 0;

  a {
    text-decoration: none;
  }

  .phone {
    text-align: right;

    @media (max-width: 991px) {
      text-align: center;
    }
  }

  .email {
    text-align: left;

    @media (max-width: 991px) {
      text-align: center;
    }
  }

  .fa-stack {
    text-align: right;
    margin-right: 0.5em;

    .fa-circle {
      font-size: 2rem;
    }
  }

  .info-row {
    @media screen and (min-width: 992px) {
      white-space: nowrap;
    }
  }
}
</style>