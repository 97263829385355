import axios from "axios"

let state = {
  paymentData: localStorage.getItem('payment-data') ? JSON.parse(localStorage.getItem('payment-data')) : null,
  order: localStorage.getItem('payment-order') ? JSON.parse(localStorage.getItem('payment-order')) : null,
  orderWaitingForPayment: !!localStorage.getItem('order-waiting-for-payment'),
  prepaymentEnabled: false
};

const actions = {
  GET_ORDER ({commit, state}) {
    axios.get('/api/user/orders/' + state.order.id)
      .then(response => {
        commit('SET_ORDER', response.data.order)
      })
  },
  ENABLE_PREPAYMENT ({commit}) {
    commit('ENABLE_PREPAYMENT')
  }
};

const mutations = {
  SET_PAYMENT_DATA (state, payload) {
    localStorage.setItem('payment-data', JSON.stringify(payload))
    state.paymentData = payload
  },
  SET_ORDER (state, payload) {
    localStorage.setItem('payment-order', JSON.stringify(payload))
    state.order = payload
  },
  REMOVE_ORDER (state) {
    localStorage.removeItem('payment-order')
    state.order = null
  },
  ENABLE_PREPAYMENT (state) {
    state.prepaymentEnabled = true
  },
  SET_ORDER_WAITING_FOR_PAYMENT (state) {
    localStorage.setItem('order-waiting-for-payment', JSON.stringify({ active: true }))
    state.orderWaitingForPayment =  true
  },
  REMOVE_ORDER_WAITING_FOR_PAYMENT (state) {
    localStorage.removeItem('order-waiting-for-payment')
    state.orderWaitingForPayment = false
  },
  REMOVE_PAYMENT_TYPES (state) {
    localStorage.removeItem('payment-data')
    state.paymentData = null
  }
};

const getters = {
  GET_PAYMENT_DATA: state => state.paymentData,
  GET_ORDER: state => state.order,
  PREPAYMENT_ENABLED: state => state.prepaymentEnabled,
  IS_ORDER_WAITING_FOR_PAYMENT: state => state.orderWaitingForPayment
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
