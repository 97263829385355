import axios from 'axios'

let state = {
  reclamation: {
    reclamations: [],
    deliveries: []
  }
}

const actions = {
  INIT_DELIVERIES (context) {
    return axios.get('/api/reclamations/deliveries')
      .then(response => {
        context.commit('SET_DELIVERIES', response.data)
      })
  },
  INIT_RECLAMATIONS (context) {
    return axios.get('/api/reclamations')
      .then(response => {
        context.commit('SET_RECLAMATIONS', response.data)
      })
  }
};

const mutations = {
  SET_DELIVERIES (state, payload) {
    state.reclamation.deliveries = {...payload} // If empty, an array is returned, cast that to object
  },
  SET_RECLAMATIONS (state, payload) {
    state.reclamation.reclamations = payload
  }
};

const getters = {
  GET_DELIVERIES: state => state.reclamation.deliveries,
  GET_RECLAMATIONS: state => state.reclamation.reclamations
};


export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
