<template>
  <div id="app">
    <div v-if="navigationDrawerIsOpen" class="overlay" @click="closeDrawers()"></div>
    <side-menu />
    <div class="foodstock-navbar" v-if="notLoginPage">
      <navbar />
    </div>
    <span v-if="mobileView">
      <side-cart />
      <side-order-template />
    </span>
    <success-notification />
    <div v-if="notLoginPage && isSearchOpen">
      <search-form />
    </div>
    <router-view />
  </div>
</template>


<script>
  if (process.env.NODE_ENV === 'production') {
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function()
      {(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:1375391,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }
  export default {
    updated () {
      if (this.notLoginPage) {
        this.subscribeToCatalogUpdated()
      }
      if (!this.notLoginPage) {
        this.unsubscribeFromCatalogUpdated()
      }
    },
    methods: {
      subscribeToCatalogUpdated () {
        if (!this.$store.getters['WebSocket/IS_SUBSCRIBED_CATALOG_CHANNEL']) {
          let channel = this.$pusher.subscribe('catalog-channel')
          channel.bind('catalog-updated', () => {
            this.$store.dispatch('Catalog/UPDATE_CATALOG')
          })
          this.$store.commit('WebSocket/SUBSCRIBE_CATALOG_CHANNEL')
        }
      },
      unsubscribeFromCatalogUpdated () {
        if (this.$store.getters['WebSocket/IS_SUBSCRIBED_CATALOG_CHANNEL']) {
          this.$pusher.unsubscribe('catalog-channel')
          this.$store.commit('WebSocket/UNSUBSCRIBE_CATALOG_CHANNEL')
        }
      },
      closeDrawers () {
        if (this.$store.getters['NavigationDrawer/MENU_OPEN']) {
          this.$store.dispatch('NavigationDrawer/TOGGLE_MENU')
        }
        if (this.$store.getters['NavigationDrawer/CART_OPEN']) {
          this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
        }
      }
    },
    computed: {
      navigationDrawerIsOpen () {
        return this.$store.getters['NavigationDrawer/MENU_OPEN'] || this.$store.getters['NavigationDrawer/CART_OPEN'] || this.$store.getters['NavigationDrawer/ORDER_OPEN']
      },
      // @TODO use .includes()
      notLoginPage () {
        return this.$route.path !== '/' && this.$route.path !== '/maintenance' && this.$route.path !== '/admin'
      },
      mobileView () {
          return document.documentElement.clientWidth < 768
      },
      isSearchOpen () {
        return  this.$store.getters['Search/SHOW_SEARCH']
      }
    }
  }
</script>

<style lang="scss">
@import './assets/scss/variables';

#app {
  font-family: 'Noir Pro', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;

  .foodstock-navbar {
    position: relative;
    width: 100%;
    z-index: 99;
    background-color: $dark;
    padding: 0;

    .inner {
      position: relative;
      width: 100%;
      max-width: $desktop-max-width;
      margin: 0 auto;

      .language {
        text-transform: uppercase;
        position: absolute;
        width: 65px;
        right: 17px;
        top: 13px;
      }
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: .5 !important;
  z-index: 50 !important;
  top: 0;
  transition: .2s;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}</style>
