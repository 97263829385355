export default {
    mounted () {
        setInterval(() => this.timerHandler(), 1000)
    },
    methods :{
        timerHandler () {
            // if time is between 13-14, show countdown timer
            if (this.$moment().format('H') === '13' && !this.$store.getters['Catalog/TIMER_ACTIVE']) {
                this.$store.dispatch('Catalog/ACTIVATE_TIMER')
            }
        }
    }
}
