import axios from 'axios'
import OrderManager from '@/managers/OrderManager'
import CartManager from '@/managers/CartManager'

let state = {
  orders: localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : [],
  orderSuccess: !!localStorage.getItem('order-success'),
  selectedOrder: null,
  selectedOrderTemplate: null,
  orderTemplates: localStorage.getItem('orderTemplates') ? JSON.parse(localStorage.getItem('orderTemplates')) : [],
  orderWaitingForPayment: null,
  orderError: {
    status: false,
    message: ''
  }
}

const actions = {
  INIT_ORDERS(context) {
    axios.get('/api/user/orders')
      .then(response => {
        let orders = response.data
        context.commit('SET_ORDERS', orders.orders)
        localStorage.setItem('orders', JSON.stringify(orders.orders))
      })
  },
  INIT_ORDER_TEMPLATES(context) {
    axios.get('/api/user/order_template')
      .then(response => {
        let orderTemplates = response.data.order_templates
        context.commit('SET_ORDER_TEMPLATES', orderTemplates)
        localStorage.setItem('orderTemplates', JSON.stringify(orderTemplates))
      })
  },
  ORDER_ITEMS({ dispatch, commit, rootGetters }, payload) {

    let cart = { deliveries: payload }
    let redirect = { name: '', order: null, payment: null }

    return axios.post('/api/user/orders', { cart })
      .then(response => {
        const data = response.data
        if (data.error) {
          dispatch('SET_ORDER_ERROR')
          return
        }
        // generic order data user everywhere
        commit('Payment/SET_ORDER', data.order, { root: true })
        commit('Modal/DISABLE_CONFIRM_ORDER', null, { root: true })
        commit('SET_ORDER_STATUS')
        // prepayment customer
        if (data.prepayment) {
          const prepayment = JSON.parse(data.prepayment)
          // set payment types (banks)
          commit('Payment/SET_PAYMENT_DATA', prepayment, { root: true })
          // set order waiting for payment
          commit('Payment/SET_ORDER_WAITING_FOR_PAYMENT', null, { root: true })
          // set redirect link
          redirect.name = 'prepayment'
          redirect.payment = prepayment
        }
        // order is paid with invoice
        else {
          if (!rootGetters['NavigationDrawer/CART_OPEN']) {
            dispatch('NavigationDrawer/TOGGLE_CART', null, { root: true })
          }
          dispatch('Cart/REMOVE_CART', null, { root: true })
          dispatch('ORDER_DONE', data.order)

          redirect.name = 'order-success'
          redirect.order = data.order

          // in mobile view, orders are made from cart open position
          if (rootGetters['NavigationDrawer/CART_OPEN']) {
            dispatch('NavigationDrawer/TOGGLE_CART', null, { root: true })
          }
        }
        commit('Modal/ENABLE_ORDER_BUTTON', null, { root: true });
        return redirect
      })
  },
  ORDER_DONE(context, payload) {
    OrderManager.updateOrders(payload)
    context.commit('ORDER_DONE', payload)
  },
  SELECT_ORDER(context, payload) {
    context.commit('SELECT_ORDER', payload)
  },
  SELECT_ORDER_TEMPLATE(context, payload) {
    context.commit('SELECT_ORDER_TEMPLATE', payload)
  },
  ORDER_AGAIN({ state, dispatch, rootGetters, commit }) {
    state.orderAgainItemsNotFound = []
    commit('ItemsNotFound/REMOVE_ITEMS', {}, { root: true })
    // init catalog checked indicators
    dispatch('Catalog/RESET_CATALOG_INDICATORS', {}, { root: true })

    state.selectedOrder.deliveries.forEach(deliveryGroup => {

      // remove auto add items
      let orderAgainItems = deliveryGroup.items.filter(item => item.auto_add_item === 0)
      orderAgainItems.forEach(item => {

        // dont order again additional items (deposit etc) or small delivery fees
        if (!item.additional_item && !item.auto_add_item && item.product_id !== 'P9996') {
          // add info to order from template
          item.orderFromTemplate = true
          dispatch('Cart/ADD_ITEM', item, { root: true })
            .then()
            .catch(response => {
              dispatch('ItemsNotFound/ADD_ITEM', response, { root: true })
            })
        }
      })
    })

    // update cart in local storage and backend
    CartManager.updateCart(rootGetters['Cart/GET_SORTED_CART'])
  },
  ORDER_FROM_TEMPLATE({ rootGetters, state, dispatch }) {
    state.orderAgainItemsNotFound = []

    state.selectedOrderTemplate.items.forEach(item => {

      // add info to order from template
      item.orderFromTemplate = true

      dispatch('Cart/ADD_ITEM', item, { root: true })
        .then()
        .catch(response => {
          dispatch('ItemsNotFound/ADD_ITEM', response, { root: true })
        })
    })

    // update cart in local storage and backend
    CartManager.updateCart(rootGetters['Cart/GET_SORTED_CART'])
  },
  ADD_ORDER_TEMPLATE({ commit, rootGetters }, payload) {
    let sortedCart = rootGetters['Cart/GET_SORTED_CART']

    let items = []

    // loop all delivery groups and add items from them to same array
    sortedCart.forEach(sortedGroup => sortedGroup.items.forEach(item => items.push(item)))

    let order_template = { name: payload, items }
    let orderTemplates = JSON.parse(localStorage.getItem('orderTemplates'))

    return axios.post('/api/user/order_template', { order_template })
      .then(response => {
        // add to state
        commit('ADD_ORDER_TEMPLATE', response.data)
        // add to local storage
        orderTemplates.unshift(response.data)
        localStorage.setItem('orderTemplates', JSON.stringify(orderTemplates))
        // return to component
        return response
      })
  },
  UPDATE_ORDER_TEMPLATE({ dispatch }, payload) {
    const items = payload.items

    let order_template = { id: payload.id, name: payload.name, items }
    return axios.post(`/api/user/order_template/${payload.id}`, { order_template })
        .then(response => {
          // Re-init order templates to fix the order in both state and local storage
          dispatch('INIT_ORDER_TEMPLATES');
          // return to component
          return response
        })
  },
  REMOVE_ORDER_TEMPLATE({ commit }, payload) {
    return axios.delete('/api/user/order_template/' + payload.id)
      .then(response => {

        // remove from state
        commit('REMOVE_ORDER_TEMPLATE', payload)

        // remove from local storage
        OrderManager.removeOrderTemplate(payload)

        // return to component
        return response
      })
  },
  SELECT_DEFAULT_ORDER_TEMPLATE(context) {
    context.commit('SELECT_DEFAULT_ORDER_TEMPLATE')
  },
  SET_ORDER_ERROR({ commit }, payload) {
    commit('SET_ORDER_ERROR_STATUS', true)
    commit('SET_ORDER_ERROR_MESSAGE', payload)
  },
  DISABLE_ORDER_ERROR({ commit }) {
    commit('SET_ORDER_ERROR_STATUS', false)
    commit('SET_ORDER_ERROR_MESSAGE', '')
  },
  SET_ORDER_STATUS({ commit }) {
    commit('SET_ORDER_STATUS')
  },
}

const mutations = {
  SET_ORDERS(state, payload) {
    let orders = []
    state.orderWaitingForPayment = null

    payload.forEach(order => {

      // if order is waiting for payment, dont add it on order list
      if (order.payment_status === 1) {
        state.orderWaitingForPayment = order
      } else {
        orders.push(order)
      }
    })
    state.orders = orders
  },
  SET_ORDER_STATUS(state) {
    localStorage.setItem('order-success', JSON.stringify({ status: true }))
    state.orderSuccess = true
  },
  ORDER_DONE(state, payload) {
    state.orders.unshift(payload)
  },
  SELECT_ORDER(state, payload) {
    state.selectedOrder = payload
  },
  SELECT_ORDER_TEMPLATE(state, payload) {
    state.selectedOrderTemplate = payload
  },
  SET_ORDER_TEMPLATES(state, payload) {
    state.orderTemplates = payload
  },
  ADD_ORDER_TEMPLATE(state, payload) {
    state.orderTemplates.unshift(payload)
  },
  REMOVE_ORDER_TEMPLATE(state, payload) {
    let orderTemplates = state.orderTemplates
    let index = orderTemplates.indexOf(payload)
    orderTemplates.splice(index, 1)

    state.orderTemplates = orderTemplates
  },
  SELECT_DEFAULT_ORDER_TEMPLATE(state) {
    if (state.orderTemplates.length > 0) {
      state.selectedOrderTemplate = state.orderTemplates[0]
    }
  },
  SET_ORDER_ERROR_STATUS(state, payload) {
    state.orderError.status = payload
  },
  SET_ORDER_ERROR_MESSAGE(state, payload) {
    state.orderError.message = payload
  },
  DISABLE_ORDER_ERROR(state) {
    state.orderError.status = false
    state.orderError.message = ''
  },
  REMOVE_ORDER_STATUS(state) {
    localStorage.removeItem('order-success')
    state.orderSuccess = false
  },
  REMOVE_SELECTED_ORDER(state) {
    state.selectedOrder = null
  }
}

const getters = {
  ORDER_SUCCESS: state => state.orderSuccess,
  GET_ORDERS: state => state.orders,
  GET_SELECTED_ORDER: state => state.selectedOrder,
  GET_SELECTED_ORDER_TEMPLATE: state => state.selectedOrderTemplate,
  GET_NOT_FOUND_ITEMS_ORDER_AGAIN: state => state.orderAgainItemsNotFound,
  GET_ORDER_TEMPLATES: state => state.orderTemplates,
  ORDER_ERROR: state => state.orderError.status,
  ORDER_ERROR_MESSAGE: state => state.orderError.message,
  ORDER_PAYMENT_CANCELLED: state => state.orderWaitingForPayment,
  ORDER_SELECTED: state => state.selectedOrder !== null
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
