<template>
  <div ref="ContinueOrderAfterLoginModal" class="modal" id="modal-oldCart" tabindex="-1"
    aria-labelledby="modal-oldCart-label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content modal-continue-order">
        <div class="modal-body">
          <div class="container-fluid text-center my-4">
            <div class="row">
              <div class="col-12">
                <new-order-icon />
              </div>
              <div class="col-12 text-center mt-4">
                <strong>{{ $t('modal.existing_order') }}</strong>
                <p class="mt-3">{{ $t('modal.empty_cart_continue') }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-6 mt-2 px-1">
                <button class="btn btn-primary w-100 foodstock-btn border-white" @click="newOrder()">{{
                  $t('button.start_new') }}</button>
              </div>
              <div class="col-6 mt-2 px-1">
                <button class="btn btn-primary w-100 foodstock-btn border-white" @click="closeModal()">{{ $t('button.ok')
                  }}</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import { Modal } from 'bootstrap';
    import NewOrderIcon from '@/components/icons/shopIndex/NewOrder'

    export default {
        components: {NewOrderIcon},
        data() {
        return {
          bsModal: null // data property to hold the Bootstrap 5 Modal instance
        };
      },
      mounted() {
        // Initialize Bootstrap 5 Modal
        this.bsModal = new Modal(this.$el);
      },
        created () {
            if (this.$route.params.oldCart) {
                this.$nextTick(() => {this.bsModal.show()});
            }
        },
        methods: {
            closeModal () {
                this.bsModal.hide();
            },
            newOrder () {
                this.$store.dispatch('Cart/REMOVE_CART');
                this.bsModal.hide();
            }
        },
    }
</script>

<style scoped></style>
