import axios from 'axios'

/**
 * Handling cart items
 *
 * @param updateCart
 * @param payload
 */
function updateCart(payload)
{
    // update local storage
    localStorage.setItem('sortedCart', JSON.stringify(payload));
    // put items in same array
    let items = [];
    payload.forEach(sortedGroup => sortedGroup.items.forEach(item => items.push(item)));
    // update cart to backend
    let cart = { items: items };
    updateCartBackend(cart)
}

function removeCart()
{
    let cart = { items: [] };
    // update cart to backend
    updateCartBackend(cart);
    // update local storage
    localStorage.setItem('cart', JSON.stringify(cart));
    // remove sorted cart
    localStorage.setItem('sortedCart', JSON.stringify([]));
}

function removeCartFromState()
{
    let cart = { items: [] };
    // update local storage
    localStorage.setItem('cart', JSON.stringify(cart));
    // remove sorted cart
    localStorage.setItem('sortedCart', JSON.stringify([]));
}

/**
 * Update amount for an item
 *
 * @param payload
 * @deprecated
 */
function updateAmount(payload)
{
    let cart = JSON.parse(localStorage.getItem('cart'));
    cart.items.forEach(item => {
        if (item.product_id === payload.product_id) {
            item.amount += parseInt(payload.amount)
        }
    });
    // update local storage
    localStorage.setItem('cart', JSON.stringify(cart));
    // update cart to backend
    updateCartBackend(cart)
}

/**
 * Update amount for item
 *
 * @param payload
 */
function updateAmountFromCart(payload)
{
    let cart = JSON.parse(localStorage.getItem('sortedCart'));
    let item = '';
    cart.forEach(sortedGroup => {
        sortedGroup.items.forEach(sortedItem => {
            if (sortedItem.product_id === payload.product_id) {
                sortedItem.amount = parseInt(payload.amount);
                item = sortedItem
            }
        })
    });
    // update local storage
    localStorage.setItem('sortedCart', JSON.stringify(cart));
    // update cart to backend
    updateOrCreateItemToBackend(item)
}

/**
 * Update whole cart to backend, used for creating order from history / template
 * @param cart
 */
function updateCartBackend(cart)
{
    let config = { headers: {'Authorization' : localStorage.getItem('access_token'), 'Accept': 'application/json'}};
    axios.post(process.env.VUE_APP_BACKEND_URL + '/api/user/cart', { cart }, config)
}

/**
 * Update cart item to backend, used for adding or changing amount of item
 * @param item
 */
function updateOrCreateItemToBackend(item) {
    let config = { headers: {'Authorization' : localStorage.getItem('access_token'), 'Accept': 'application/json'}};
    axios.post(process.env.VUE_APP_BACKEND_URL + '/api/user/cart/item', item, config)
}

function deleteItemToBackend(item) {
    let config = { headers: {'Authorization' : localStorage.getItem('access_token'), 'Accept': 'application/json'}};
    axios.delete(process.env.VUE_APP_BACKEND_URL + '/api/user/cart/item', { headers: config, data: item })
}

export default {
    updateCart,
    deleteItemToBackend,
    removeCart,
    updateAmount,
    updateAmountFromCart,
    updateCartBackend,
    removeCartFromState,
    updateOrCreateItemToBackend
}
