<template>

  <div class="login-wrapper">
    <div class="container">
      <transition name="fade" appear>
        <div v-if="error" class="notify shadow">
          <div class="notify__title">
            {{$t('login.session_expired')}}
          </div>
          <div class="notify__message">
            {{$t('login.login_again')}}
          </div>
        </div>
      </transition>
      <transition name="fade" appear>
        <div class="row pt-3 pb-4 pt-md-5 pb-md-5">
          <div class="col text-center">
            <img src="@/assets/foodstock_logo.png" alt="Logo" class="logo-foodstock">
          </div>
        </div>
      </transition>
      <div class="row px-2 px-md-0">
        <div class="col-12 col-md-6 offset-md-3 shadow p-0 mt-md-5">
          <div class="card position-relative">
            <div class="card-body py-5 p-md-5">
              <login-form/>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 mt-md-5">
        <div class="col-12 text-center">
          <p class="text-uppercase"><strong>{{$t('form.forget_credentials')}}</strong></p>
        </div>
      </div>
      <contact-info color="white" />
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm'
import ContactInfo from '@/components/general/ContactInfo'
export default {
  components: {LoginForm, ContactInfo},
  data () {
    return {
      error: false
    }
  },
  created () {
    if (this.$route.params && this.$route.params.unauthorized) {
      this.error = true
      // handle un authorized user data
      this.$store.dispatch('Auth/LOG_OUT_UN_AUTH')
      setTimeout(() => this.error = false, 5000)
    }
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.forget-credentials {
  span {
    font-size: .7rem;
  }
}
.login-wrapper {
  color: white;
  height: 100vh;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 768px) {
    background-size: 150%;
    height: 100%;
  }
}
.logo-foodstock {
  max-width: 10%;
  /* Mobile screen */
  @media screen and (max-width: 768px) {
    max-width: 30%;
  }
}
.icon-wrapper {
  margin-right: .5rem;
}
.notify {
  width: 22rem;
  height: 4.5rem;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  top: 10%;
  background-color: white;
  border-left: 10px solid #dc3545;
  z-index: 10;

  /* Mobile screen */
  @media screen and (max-width: 768px) {
    left: 10px;
    top: 2rem;
    width: 19rem;
  }

  &__title {
    color: #dc3545;
    font-weight: bold;
    padding: .5rem 0 .3rem 1.5rem;
  }

  &__message {
    color: black;
    padding-left: 1.5rem;
    font-size: .9rem;
  }
}
</style>
