import axios from 'axios'

let state = {
    notifications: null
};

const actions = {
    GET_NOTIFICATIONS (context) {
        axios.get('/api/user/notification')
            .then(response => {
                context.commit('SET_NOTIFICATIONS', response.data.notifications)
            })
    }
};

const mutations = {
    SET_NOTIFICATIONS (state, payload) {
        state.notifications = payload
    }
};

const getters = {
    GET_NOTIFICATIONS: state => state.notifications
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
