<template>
  <div class="mb-5">
    <div class="row">
      <div class="col-12">
        <div class="card">

          <!-- HEADER -->
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-sm-8 pe-1">
                <span>
                  <i class="fa fa-solid fa-star me-3"></i>
                </span>
                <strong>Kokeile uutta tilaustyökalua</strong>
              </div>
              <div class="col-sm-4 text-end">
                <button class="btn btn-primary w-45 me-1" @click="openCampaignTool">{{$t('campaign.open')}}</button>
                <!-- Campaign tool closing button has been disabled at least for piloting phase -->
                <!-- <button class="btn btn-secondary w-45" @click="$emit('closeCampaign')">{{$t('campaign.close')}}</button> -->
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['closeCampaign'],
  methods: {
    openCampaignTool() {
      this.$router.push('/shop/categories-beta')
    }
  }
}
</script>
