<template>
    <div class="countdown d-print-none">
        <div class="text-white p-2">
            <span class="countdown__info">{{$t('general.countdown_info')}}: </span>
            <span class="countdown__duration">{{ duration_minutes }}:{{ duration_seconds }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                duration_minutes: '60',
                duration_seconds: '00'
            }
        },
        mounted () {
            setInterval( () => this.countDown(), 1000)
        },
        methods: {
            countDown () {
                let date = new Date;
                let seconds = 60 - date.getSeconds();
                let minutes = 60 - date.getMinutes() - 1; // default 00 value is 60

                if (this.$moment().format('H') === '14' && this.$store.getters['Catalog/TIMER_ACTIVE']) {
                    this.$store.dispatch('Catalog/DISABLE_TIMER');
                    this.$store.dispatch('Catalog/UPDATE_CATALOG');
                }

                if (minutes > 0 && seconds === 60) {
                    this.duration_seconds = '00'
                } else if (seconds < 10) {
                    this.duration_seconds = '0' + seconds;
                } else {
                    this.duration_seconds = seconds;
                }

                if (minutes <= 0) {
                    this.duration_minutes = '00';
                } else if (minutes < 10) {
                    this.duration_minutes = '0' + minutes;
                } else {
                    this.duration_minutes = minutes;
                }
            }
        }
    }
</script>