import axios from 'axios'

let state = {
  faqs: null,
  lastOpen: null
};

const actions = {
  INIT_FAQS (context) {
    axios.get('/api/user/faq')
      .then(response => {
        response.data.faqs.map(faq => faq.open = false);
        context.commit('SET_FAQS', response.data.faqs)
      })
  }
};

const mutations = {
  SET_FAQS (state, payload) {
    state.faqs = payload
  }
};

const getters = {
  GET_FAQS: state => state.faqs
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
