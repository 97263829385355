<template>
    <div>
        <strong class="text-danger">{{$t('notification.error')}}</strong>
    </div>
</template>

<script>
    export default {
        name: "ErrorNotification"
    }
</script>

<style scoped>

</style>