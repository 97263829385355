let state = {
    isSubscribedCatalogChannel: false
};

const mutations = {
    SUBSCRIBE_CATALOG_CHANNEL: (state) => {
        state.isSubscribedCatalogChannel = true
    },
    UNSUBSCRIBE_CATALOG_CHANNEL: (state) => {
        state.isSubscribedCatalogChannel = false
    },
};

const getters = {
    IS_SUBSCRIBED_CATALOG_CHANNEL: state => state.isSubscribedCatalogChannel
};

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
