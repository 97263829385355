<template>
  <div :class="['sidemenu-wrapper', {'menu-open': isMenuOpen, 'menu-closed': !isMenuOpen, 'd-none': isLoginPage}]">
    <div v-if="!isLoginPage">
      <div>
        <div class="sidemenu-items-container">
          <span class="menu-username">{{name}}</span>
          <ul>
            <li v-for="item in menuItems" :key="item.name" @click="$event.target.classList.toggle('active')">
              <div v-if="item.router" class="top-level" @click="closeMenu()">
                <router-link :to="item.routerLink" class="text-decoration-none">
                  <i :class="item.icon"></i> {{ item.name }}
                </router-link>
              </div>
              <div v-else class="top-level parent">
                <a :class="{ active: item.isShow }" class="text-decoration-none"><i :class="item.icon"></i> {{ item.name
                  }}</a>
                <div class="submenu">
                  <ul v-if="item.children">
                    <li v-for="subItem in item.children" :key="subItem.id" @click="closeMenu()">
                      <router-link v-if="subItem.id === 1" :to="{name: 'categories', params: {newOrder: true}}"
                        class="text-decoration-none">
                        <i :class="subItem.icon"></i> {{ subItem.name }}
                      </router-link>
                      <router-link v-else :to="subItem.routerLink" class="text-decoration-none">
                        <i :class="subItem.icon"></i> {{ subItem.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li @click="closeMenu()">
              <div @click="initExportExcel()" class="top-level">
                <a class="text-decoration-none"><i class="fa-solid fa-download"></i> {{ $t('menu.load_product_list')
                  }}</a>
              </div>
            </li>
            <li>
              <div class="top-level parent" @click="$event.target.classList.toggle('active')">
                <a class="active text-decoration-none"><i class="fa-solid fa-user"></i> {{ $t('Account') }}</a>
                <div class="submenu">
                  <ul>
                    <li>
                      <a @click="logout()"><i class="fa-solid fa-sign-out-alt"></i> {{$t('button.logOut')}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
export default {
  methods: {
    logout () {
      this.$store.dispatch('Auth/LOGOUT')
        .then(() => {
          this.$router.push('/');
        })
    },
    closeMenu () {
      this.$store.dispatch('NavigationDrawer/TOGGLE_MENU')
    },
    toggleCart() {
      if (document.documentElement.clientWidth < 768) {
        if (this.$store.getters['NavigationDrawer/MENU_OPEN']) {
          this.$store.dispatch('NavigationDrawer/TOGGLE_MENU')
        }
        if (this.$store.getters['NavigationDrawer/CART_OPEN']) {
          document.querySelector('body').classList.remove('overflow-hidden');
        } else {
          document.body.className = 'overflow-hidden';
        }
        this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
      }
    },
    initExportExcel() {
      this.$store.dispatch('Catalog/INIT_PRODUCTS')
          .then(() => this.exportExcel())
    },
    exportExcel () {
      const ws = XLSX.utils.aoa_to_sheet(this.catalogToExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Foodstock - Catalog");
      XLSX.writeFile(wb, this.excelFileName);
    },
  },
  computed: {
    name() {
      return this.$store.getters['Auth/GET_NAME']
    },
    isCategoryPage () {
      return this.$store.getters['NavigationDrawer/IS_CATEGORY_PAGE']
    },
    isMenuOpen () {
      return this.$store.getters['NavigationDrawer/MENU_OPEN']
    },
    isLoginPage () {
      return this.$route.path === '/'
    },
    isMobile () {
      return document.documentElement.clientWidth < 768
    },
    menuItems () {
      let menuItems = [
        {
          id: 10, name: this.$t('Orders'), router: false, icon: 'fa-solid fa-pen', isShow: true, children: [
            {id: 1, name: this.$t('menu.new_order'), router: true, icon: 'fa fa-plus-square', routerLink: '/shop/categories'},
            {id: 5, name: this.$t('menu.order_history'), router: true, icon: 'fa fa-clipboard', routerLink: '/order/orders'},
            {id: 10, name: this.$t('menu.order_templates'), router: true, icon: 'fa fa-clone', routerLink: '/order/templates'}
          ]
        },
      ]
      if (this.$store.getters['Auth/GET_RECLAMATION_PERMISSION']) {
        menuItems.push(
          {
            id: 20, name: this.$t('Claims'), router: false, icon: 'fa-solid fa-truck', isShow: false, children: [
              {id: 15, name: this.$t('menu.claim'), router: true, icon: 'fa fa-plus-square', routerLink: '/reclamation'},
              {id: 20, name: this.$t('menu.claim_list'), router: true, icon: 'fa-solid fa-history', routerLink: '/reclamation/list'}
            ]
          },
        )
      }
      menuItems.push(
        {id: 25, name: this.$t('menu.faq'), router: true, icon: 'fa-solid fa-question', routerLink: '/faq', isShow: true, children: []},
        {id: 30, name: this.$t('menu.inventory'), router: true, icon: 'fa-solid fa-calculator', routerLink: '/inventory', isShow: true, children: []},
        {id: 35, name: this.$t('menu.feedback'), router: true, icon: 'fa-solid fa-comment-alt', routerLink: '/feedback', isShow: true, children: []},
      )
      if (!this.$store.getters['Cart/EMPTY_CART']) {
        menuItems.push({id: 0, name: this.$t('menu.continue_order'), router: true, icon: 'fa fa-file-edit', routerLink: '/shop/categories', isShow: true, children: []});
        menuItems = this.$_.sortBy(menuItems, 'id')
      }
      return menuItems
    },
    catalogToExcel () {
      return this.$store.getters['Catalog/GET_PRODUCTS_TO_EXCEL']
    },
    excelFileName () {
      let date = new Date;
      return `Foodstock-catalog-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.xlsx`
    }
  }
}
</script>