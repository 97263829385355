<template>
    <div :key="item.price_id" class="px-2 cart-item-row">
        <div class="row px-2 cart-item-row" :class="{ 'bg-danger-subtle': item.amount === 0 }">
            <div class="col p-0 item-name" :id="'cart-item-' + item.product_id">
                <p class="mb-1">{{ parseDescriptionLocale }}</p>
                <p v-if="extraInfo" class="small mb-0">{{ $t('cart.product_id') }}: {{ item.product_id }}</p>
                <p v-if="extraInfo" class="small mb-0">{{ $t('cart.price') }}: {{ totalPrice | currency }}
                    ({{ $t('cart.incl_vat') }})</p>
                <p v-if="extraInfo && additionalItemsTotalPrice > 0" class="small mb-0">
                    {{ $t('cart.deposit_price') }}: {{ additionalItemsTotalPrice | currency }} ({{ $t('cart.incl_vat')
                    }})
                </p>
            </div>
            <div class="col-3">
                <div class="tooltip-amount">
                    <input v-bind:id="'cart-' + item.product_id" type="number"
                        class="form-control shopping-cart-item-amount p-0"
                        :class="{ 'bg-danger-subtle': item.amount === 0 }" v-model.number="localAmount"
                        ref="cartItemAmount" @change="updateAmount(item)" :state="validateInput(item)"
                        @keypress="isNumber($event)" @input="inputListeners" />
                </div>
                <div v-if="bigAmount" class="tooltip-amount__tooltip">
                    <div class="tooltip-amount__tooltip__title">
                        <div class="tooltip-amount__tooltip__title__text">{{ $t('notification.amount_title') }}</div>
                        <span class="tooltip-amount__tooltip__title__close" @click="bigAmount = false">&#10005;</span>
                    </div>
                    <div class="tooltip-amount__tooltip__info">
                        {{ $t('notification.amount_message') }}
                    </div>
                </div>
            </div>
            <div class="col-1 text-center p-0">
                <span @click="removeItem(item)" class="remove-item"><i class="fa fa-trash-alt"></i></span>
            </div>

        </div>

        <hr class="w-100 my-2" />
        <!-- REMOVE ITEM FROM CART -->
        <remove-item-from-cart v-if="removeItemModalOpen" :closeModal="closeRemoveItemModal" />
    </div>
</template>

<script>
import RemoveItemFromCart from "@/components/modals/RemoveItemFromCart.vue";

export default {
    components: {RemoveItemFromCart},
    props: ['item', 'extraInfo'],
    data() {
        return {
            bigAmount: false,
            removeItemModalOpen: false
        }
    },
    methods: {
        closeRemoveItemModal() {
            this.removeItemModalOpen = false
        },
        updateAmount(item) {
            if (Number.isInteger(item.amount)) {
                this.$store.dispatch('Cart/UPDATE_ITEM_AMOUNT_FROM_CART', item)
            }
        },
        removeItem(item) {
            this.removeItemModalOpen = true
            this.$store.commit('Cart/SET_REMOVE_ITEM', item)
        },
        validateInput(item) {
            if (item.amount <= 0) {
                return false
            }
            return null
        },
        isNumber(event) {
            if (event.key == '-' || event.key == '+') {
                event.preventDefault();
            } else {
                return true
            }
        },
        inputListeners(event) {
            const amount = event.target.value
            amount >= 50 ? this.bigAmount = true : this.bigAmount = false
        }
    },
    computed: {
        localAmount: {
            get() {
                return this.item.amount;
            },
            set(newVal) {
                this.updateAmount({...this.item, amount: newVal});  // Update using Vuex or other methods
            }
        },
        additionalItemsTotalPrice() {
            let total = 0
            for (let i = 0; i < this.item.additional_items.length; i++) {
                total += parseFloat(this.item.additional_items[i].unit_price_incl_tax) * parseInt(this.item.amount)
            }
            return total
        },
        totalPrice() {
            return this.item.price_incl_tax * this.item.amount
        },
        parseDescriptionLocale() {
            const localizedField = `description_${this.$i18n.locale}`
            return this.item[localizedField]
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';

.remove-item {
    cursor: pointer;
    z-index: 5;
}

.tooltip-amount {
    position: relative;

    &__tooltip {
        -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.6);
        -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.6);
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.6);
        font-size: 14px;
        position: absolute;
        background-color: white;
        min-height: 4rem;
        width: 25rem;
        top: 45px;
        right: -30px;
        z-index: 1;
        border-left: 5px solid tomato;
        padding: .7rem;

        @media screen and (max-width: $mobile-breakpoint) {
            width: 17rem;
            left: -180px;
        }

        &__title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: .5rem;

            &__text {
                color: tomato;
                font-size: 14px;
                font-weight: $foodstock-font-bold;
            }

            &__close {
                cursor: pointer;
                font-weight: $foodstock-font-bold;
            }
        }
    }
}
</style>
