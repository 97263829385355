import axios from 'axios'
import CartManager from '@/managers/CartManager'

let state = {
  cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : null,
  sortedCart: localStorage.getItem('sortedCart') ? JSON.parse(localStorage.getItem('sortedCart')) : [],
  updating: false,
  removeItem: null
}

const actions = {
  INIT_CART({commit}) {
    return axios.get('/api/user/cart')
      .then(response => {
        let cart = response.data.cart
        localStorage.setItem('cart', JSON.stringify(cart))
        commit('INIT_CART', cart)
        // if cart includes extra fees
        if (cart.extra_fees && cart.extra_fees.length > 0) {
          commit('ExtraFees/SET_EXTRA_FEES', cart.extra_fees, { root: true })
        }
        // set min order amount
        commit('ExtraFees/SET_MIN_ORDER_AMOUNT', cart.min_order_amount, { root: true })
        return response
      })
  },
  ADD_PRODUCTS_FROM_BACKEND_TO_CART({state, dispatch}) {
    let cart = state.cart
    state.sortedCart = []
    cart.items.forEach(cartItem => {
      // add flag to not update cart in backend
      cartItem.orderFromTemplate = true
      // add item to sorted cart
      dispatch('ADD_ITEM', cartItem)
    })
  },
  ADD_PRODUCTS_FROM_ORDER_WAITING_FOR_PAYMENT_TO_CART({dispatch}, payload) {
    let items = []
    // get all items from sorted cart
    payload.deliveries.forEach(sortedGroup => {
      sortedGroup.items.forEach(item => {
        // add flag to not update cart in backend
        item.orderFromTemplate = true
        // add item to sorted cart
        dispatch('ADD_ITEM', item)
        // add items to array and update backend cart
        items.push(item)
      })
    })
    CartManager.updateCartBackend({cart: {items: items}})
  },
  UPDATE_ITEM_AMOUNT_FROM_CART(context, payload) {
    context.commit('UPDATE_ITEM_AMOUNT_SORTED_CART_FROM_CART', payload)
  },
  REMOVE_CART(context) {
    CartManager.removeCart()
    context.commit('REMOVE_CART')
  },
  REMOVE_CART_FROM_STATE(context) {
    context.commit('REMOVE_CART_FROM_STATE')
  },
  REMOVE_ITEM({commit, rootGetters}, payload) {
    const autoAddProductLocations = rootGetters['AutoAddProduct/GET_LOCATIONS']
    CartManager.deleteItemToBackend(payload)
    commit('REMOVE_ITEM', payload)
    commit('REMOVE_ITEM_FROM_SORTED_CART', {product: payload, autoAddProductLocations})
  },
  ADD_ITEM({rootGetters, commit}, payload) {
    return new Promise(((resolve, reject) => {
      let products = rootGetters['Catalog/GET_PRODUCTS']
      let catalog = rootGetters['Catalog/GET_CATALOG']
      let autoAddProductsIsAvailable = rootGetters['Catalog/AUTO_ADD_PRODUCTS_AVAILABLE']
      let parsedAutoAddProducts = []
      // mark category checked when adding item from there
      catalog.categories.forEach(category => {
        if (category.code === payload.category) {
          category.checked = true
        }
      })
      // find product from products
      const selectedItem = products.filter(product => {
        if (product.id === payload.product_id) {
          return product
        }
      })
      // if product is found in product list, add it to cart
      if (selectedItem.length > 0 && !selectedItem[0].out_of_stock) {
        let item = {
          price_id: selectedItem[0].price_id,
          product_id: selectedItem[0].id,
          delivery_dates: selectedItem[0].delivery_dates,
          delivery_date: payload.delivery_date,
          amount: payload.amount,
          description: selectedItem[0].description,
          autoAddProductIds: payload.autoAddProductIds,
          orderFromTemplate: payload.orderFromTemplate === true,
          vat_percent: selectedItem[0].vat_percent
        }
        // map item data from catalog based on item price id
        products.forEach(product => {
          if (product.price_id === item.price_id) {
            item.product_id = product.id
            item.description_fi = product.description_fi
            item.description_en = product.description_en
            item.location_code = product.location_code // location code is needed for auto add products
            item.price_incl_tax = product.price_incl_tax
            item.price_excl_tax = product.price_excl_tax
            item.delivery_dates = product.delivery_dates
            item.additional_items = product.additional_items
          }
        })
        // check if there is available auto add items, and added item location code is valid TODO map values from product catalog to auto add item also
        if (autoAddProductsIsAvailable) {
          const autoAddProductLocations = rootGetters['AutoAddProduct/GET_LOCATIONS']
          let autoAddProducts = rootGetters['Catalog/GET_AUTO_ADD_PRODUCTS']
          autoAddProducts.forEach(autoAddProduct => {
            if (autoAddProduct.location_code !== null && autoAddProductLocations.includes(item.location_code)) {
              parsedAutoAddProducts.push(autoAddProduct)
            } else if (autoAddProduct.location_code !== null && autoAddProduct.location_code === item.location_code) {
              parsedAutoAddProducts.push(autoAddProduct)
            } else if (autoAddProduct.location_code === null && item.location_code === null) {
              parsedAutoAddProducts.push(autoAddProduct)
            }
          })
        }
        commit('ADD_ITEM', {item: item, autoAddProducts: parsedAutoAddProducts, commit: commit})
        resolve({ status: 'added', product: payload.description })
      } else {
        reject({ status: 'not added', product: payload })
      }
    }))
  },
  SET_UPDATING({commit}) {
    commit('SET_UPDATING')
  },
  SET_UPDATED({commit}) {
    commit('SET_UPDATED')
  },
  UPDATE_DELIVERY_DATE_FOR_GROUP({commit}, payload) {
    commit('UPDATE_DELIVERY_DATE_FOR_GROUP', payload)
  }
}

const mutations = {
  REMOVE_CART(state) {
    state.cart.items = []
    state.sortedCart = []
  },
  REMOVE_SORTED_CART(state) {
    state.sortedCart = []
  },
  REMOVE_CART_FROM_STATE(state) {
    state.cart.items = []
    state.sortedCart = []
    CartManager.removeCartFromState()
  },
  REMOVE_ITEM(state, payload) {
    let itemIndex = state.cart.items.indexOf(payload)
    state.cart.items.splice(itemIndex, 1)
  },
  SET_REMOVE_ITEM(state, payload) {
    state.removeItem = payload
    state.removeItemStatus = true
  },
  UPDATE_ITEM_AMOUNT_SORTED_CART_FROM_CART(state, payload) {
    let sortedCart = state.sortedCart
    let updateItem
    sortedCart.forEach(sortedGroup => {
      sortedGroup.items.forEach(item => {
        if (item.product_id === payload.product_id) {
          item.amount = parseInt(payload.amount)
          updateItem = item
        }
      })
    })
    // add sorted cart to sessionStorage
    localStorage.setItem('sortedCart', JSON.stringify(sortedCart))
    CartManager.updateAmountFromCart(updateItem)
  },
  INIT_CART(state, payload) {
    state.cart = payload
  },
  SET_UPDATING(state) {
    state.updating = true
  },
  SET_UPDATED(state) {
    state.updating = false
  },
  ADD_ITEM(state, payload) {
    let item = payload.item
    let commit = payload.commit
    let autoAddProducts = payload.autoAddProducts
    let sortedCart = state.sortedCart
    let updateItem
    if (sortedCart.length > 0) {
      let found = false
      // loop all delivery groups
      sortedCart.forEach(sortedGroup => {
        // if added item is with same delivery dates
        if (arraysEqual(item.delivery_dates, sortedGroup.delivery_dates)) {
          found = true
          let itemInCart = sortedGroup.items.filter(sortedItem => sortedItem.product_id === item.product_id)
          // same item is in cart, increase the amount of the item
          if (itemInCart.length > 0) {
            itemInCart[0].amount = parseInt(itemInCart[0].amount) + parseInt(item.amount)
            // update item to backend
            updateItem = itemInCart[0]
          } else {
            sortedGroup.items.push(item)
            // update item to backend
            updateItem = item
          }
          // auto add products available
          if (autoAddProducts.length > 0) {
            autoAddProducts.forEach(autoAddProduct => {
              // if auto add product is not added from this location
              if (!sortedGroup.auto_add_products.some(product => product.id === autoAddProduct.id)) {
                autoAddProduct.showAutoAddProductNotification = true

                // if auto add product has been in cart before update catalog, dont show notification
                if (item.autoAddProductIds && item.autoAddProductIds.filter(autoAddId => autoAddId === autoAddProduct.id).length > 0) {
                  autoAddProduct.showAutoAddProductNotification = false
                }
                sortedGroup.auto_add_products.push(autoAddProduct)
                commit('AutoAddProduct/ADD_AUTO_ADD_PRODUCTS', autoAddProducts, {root: true})
              }
            })
          }
        }
      })
      // if there is not any group with same delivery dates
      if (!found) {
        // if there is auto add products from this location
        if (autoAddProducts.length > 0) {
          autoAddProducts.forEach(autoAddProduct => {
            autoAddProduct.showAutoAddProductNotification = true
            // if auto add product has been in cart before update catalog, dont show notification
            if (item.autoAddProductIds && item.autoAddProductIds.filter(autoAddId => autoAddId === autoAddProduct.id).length > 0) {
              autoAddProduct.showAutoAddProductNotification = false
            }
          })
          commit('AutoAddProduct/ADD_AUTO_ADD_PRODUCTS', autoAddProducts, {root: true})
        }
        sortedCart.push({
          delivery_dates: item.delivery_dates,
          delivery_date: isDeliveryDateAllowed(item) ? item.delivery_date : item.delivery_dates[0],
          delivery_dates_options: parseDates(item.delivery_dates),
          items: [item],
          auto_add_products: autoAddProducts.length > 0 ? autoAddProducts : []
        })
        // update item to backend
        updateItem = item
      }
    }
    // its first item in cart
    else {
      // if there is auto add products available
      if (autoAddProducts.length > 0) {
        autoAddProducts.forEach(autoAddProduct => {
          autoAddProduct.showAutoAddProductNotification = true
          // if auto add product has been in cart before update catalog, dont show notification
          if (item.autoAddProductIds && item.autoAddProductIds.filter(autoAddId => autoAddId === autoAddProduct.id).length > 0) {
            autoAddProduct.showAutoAddProductNotification = false
          }
        })
        commit('AutoAddProduct/ADD_AUTO_ADD_PRODUCTS', autoAddProducts, { root: true })
      }
      sortedCart.push({
        delivery_dates: item.delivery_dates,
        delivery_date: isDeliveryDateAllowed(item) ? item.delivery_date : item.delivery_dates[0],
        delivery_dates_options: parseDates(item.delivery_dates),
        items: [item],
        auto_add_products: autoAddProducts.length > 0 ? autoAddProducts : []
      })
      // update item to backend
      updateItem = item
    }
    // if item is added from catalog
    if (!item.orderFromTemplate) {
      CartManager.updateOrCreateItemToBackend(updateItem)
    }
    // add sorted cart to sessionStorage
    localStorage.setItem('sortedCart', JSON.stringify(sortedCart))
  },
  REMOVE_ITEM_FROM_SORTED_CART(state, payload) {
    let sortedCart = state.sortedCart
    let cartItemLocations = []
    sortedCart.forEach(sortedCartGroup => {
      sortedCartGroup.items.forEach(sortedItem => {
        cartItemLocations.push(sortedItem.location_code)
        if (sortedItem.product_id === payload.product.product_id) {
          let itemIndex = sortedCartGroup.items.indexOf(payload.product)
          sortedCartGroup.items.splice(itemIndex, 1)
        }
        // if this was last item for this delivery group, remove group
        if (sortedCartGroup.items.length === 0) {
          let itemIndex = sortedCart.indexOf(sortedCartGroup)
          sortedCart.splice(itemIndex, 1)
        }
      })
      // if delivery group has auto add items, check that there is still left items from auto add product locations
      if (sortedCartGroup.auto_add_products.length > 0) {
        // if there is now items in cart from auto add products locations, remove auto add products
        let found = false
        cartItemLocations.forEach(cartItemLocation => {
          if (payload.autoAddProductLocations.includes(cartItemLocation)) {
            found = true
          }
        })
        if (!found) {
          sortedCartGroup.auto_add_products = []
        }
      }
    })
    // add sorted cart to sessionStorage
    localStorage.setItem('sortedCart', JSON.stringify(state.sortedCart))
  },
  UPDATE_DELIVERY_DATE_FOR_GROUP(state, payload) {
    let sortedCart = state.sortedCart
    sortedCart[payload.index].delivery_date = payload.delivery_date
    sortedCart[payload.index].items.forEach(item => {
      item.delivery_date = payload.delivery_date
    })
    localStorage.setItem('sortedCart', JSON.stringify(state.sortedCart))
  }
}
const getters = {
  GET_CART: state => state.cart,
  EMPTY_CART: state => state.sortedCart.length === 0 || null,
  GET_ITEMS_IN_CART: state => {
    let items = []
    state.sortedCart.forEach(sortedGroup => sortedGroup.items.forEach(item => items.push(item)))
    return items
  },
  ITEMS_IN_CART: state => {
    let amount = 0
    state.sortedCart.forEach(sortedGroup => amount += sortedGroup.items.length)
    return amount
  },
  CART_UPDATING: state => state.updating,
  GET_SORTED_CART: state => state.sortedCart,
  GET_REMOVE_ITEM: state => state.removeItem,
  ITEM_AMOUNTS_NOT_VALID: state => itemAmountsNotValid(state.sortedCart)
}
function parseDates(dates) {
  let options = []
  dates.forEach(date => options.push({start: date, end: date}))
  return options
}
// valid amount for cart items and prevent order sending with non valid amounts
function itemAmountsNotValid(sortedCart) {
  let notValid = false
  sortedCart.forEach(sortedGroup => {
    sortedGroup.items.forEach(item => {
      if (item.amount === '' || item.amount <= 0) {
        notValid = true
      }
    })
  })
  return notValid
}
function isDeliveryDateAllowed(item) {
  if (typeof item.delivery_date !== 'string') {
    return false
  }
  if (!Array.isArray(item.delivery_dates)) {
    return false
  }
  if (item.delivery_dates.indexOf(item.delivery_date) < 0) {
    return false
  }
  return true
}
function arraysEqual(a1, a2) {
  return JSON.stringify(a1) == JSON.stringify(a2)
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
