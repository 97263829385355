<template>
    <div >
        <draggable
            :list="orderedTemplate.items"
            class="list-group"
            ghost-class="ghost"
            @start="dragging = true"
            @end="dragging = false"
        >
            <div
                class="list-group-item"
                v-for="item in orderedTemplate.items"
                :key="item.product_id"
            >
                <i class="fa-solid fa-grip-vertical opacity-25 me-4" />{{ item[`description_${$i18n.locale}`] }}
            </div>
        </draggable>
        <div class="row">
            <div class="col-12 text-center">
                <button v-if="!this.areArraysInSameOrder(this.originalTemplate.items, this.orderedTemplate.items)" type="button" class="btn btn-primary mt-3 w-auto foodstock-btn me-2" @click="saveChangedTemplate" :disabled="this.savingTemplateOrder">
                    {{ $t('button.update_template') }}
                </button>
                <button type="button" class="btn btn-primary mt-3 w-auto foodstock-btn" @click="orderFromTemplate" :disabled="this.savingTemplateOrder">
                    <span v-if="this.savingTemplateOrder">{{ $t('button.saving_template') }}</span>
                    <span v-else>{{ $t('button.order_from_template') }}</span>
                    <div v-if="this.savingTemplateOrder" class="ms-3 spinner-border spinner-border-sm" role="status"></div>
                </button>
            </div>
        </div>
        <order-from-template-cart-not-empty v-if="this.orderTemplateModalOpen" :closeParentModal="this.closeModal"/>
    </div>
</template>

<script>

import OrderFromTemplateCartNotEmpty from '@/components/modals/OrderFromTemplateCartNotEmpty'
import draggable from "vuedraggable";

export default {
    props: ['selectedOrderTemplate'],
    components: {OrderFromTemplateCartNotEmpty, draggable},
    data() {
        return {
            originalTemplate: this.deepCopy(this.selectedOrderTemplate),
            orderedTemplate: {},
            orderTemplateModalOpen: false,
            dragging: false,
            savingTemplateOrder: false
        }
    },
    created() {
        this.orderedTemplate = this.deepCopy(this.selectedOrderTemplate)
    },
    computed: {
        itemsInCart() {
            return this.$store.getters['Cart/GET_ITEMS_IN_CART'];
        },
    },
    methods: {
        deepCopy(obj) {
            if (typeof obj !== 'object' || obj === null) {
                return obj; // Return primitive values as is
            }

            if (Array.isArray(obj)) {
                // If it's an array, create a deep copy of each element
                return obj.map(this.deepCopy);
            }

            // If it's an object, create a deep copy of each property
            const copiedObject = {};
            for (const key in obj) {
                // eslint-disable-next-line no-prototype-builtins
                if (obj.hasOwnProperty(key)) {
                    copiedObject[key] = this.deepCopy(obj[key]);
                }
            }
            return copiedObject;
        },
        areArraysInSameOrder(array1, array2) {
            if (array1.length !== array2.length) {
                return false; // Arrays have different lengths, so they can't be in the same order
            }

            for (let i = 0; i < array1.length; i++) {
                if (array1[i].product_id !== array2[i].product_id) {
                    return false; // Objects at index i have different product_id values
                }
            }

            return true; // All objects in the same order
        },
        closeModal() {
            this.orderTemplateModalOpen = false
            if (this.$store.getters['NavigationDrawer/CART_OPEN']) {
                this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
            }
        },
        orderHelper() {
            // if cart is not empty, confirm continue
            if (!this.$store.getters['Cart/EMPTY_CART']) {
                if (document.documentElement.clientWidth < 768) {
                    this.$store.dispatch('NavigationDrawer/TOGGLE_ORDER_TEMPLATE');
                }
            } else {
                this.$store.dispatch('Order/ORDER_FROM_TEMPLATE')
                    .then(() => {
                        if (document.documentElement.clientWidth < 768) {
                            this.$store.dispatch('NavigationDrawer/TOGGLE_ORDER_TEMPLATE');
                        }
                        this.$router.push({name: 'categories', params: {orderAgain: true}})
                    })
            }
        },
        orderFromTemplate() {
            if (this.itemsInCart.length) {
                this.orderTemplateModalOpen = true
            }
            // check that catalog is in the local storage
            if (!this.$store.getters['Catalog/GET_CATALOG']) {
                this.$store.dispatch('Catalog/INIT_CATALOG')
                    .then(() => {
                        this.orderHelper();
                    })
            } else {
                this.orderHelper();
            }
        },
        saveChangedTemplate() {
            this.savingTemplateOrder = true
            this.saveTemplate()
            this.originalTemplate = this.deepCopy(this.orderedTemplate)
        },
        saveTemplate () {
            let vue = this
            vue.loading = true
            vue.$store.dispatch('Order/UPDATE_ORDER_TEMPLATE', vue.orderedTemplate)
                .then(function () {
                    vue.$store.dispatch('Order/SELECT_ORDER_TEMPLATE', vue.originalTemplate);
                })
                .then(function () {
                    vue.loading = false
                    vue.savingTemplateOrder = false
                })
        }
    }
}
</script>

<style scoped>

</style>
