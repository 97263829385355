<template>
  <div class="d-print-none nav-container w-100">
    <div class="d-flex justify-between gap-3 px-3 px-xxl-0">
      <div class="d-inline-flex justify-content-start align-items-center flex-grow-1 gap-3">
        <div class="hamburger-container pt-3 pb-3 text-center" @click="toggleSideMenu">
          <div :class="[{'d-none': !isMenuOpen }]">
            <i class="fa fa-times fa-2x text-white"></i>
          </div>
          <div :class="[{'d-none': isMenuOpen }]">
            <i class="fa fa-bars fa-2x text-white"></i>
          </div>
        </div>
        <div class="foodstock-logo-container text-white" @click="goToFrontPage()">
          <img src="@/assets/foodstock_logo.png" width="45" height="45" alt="Logo" class="logo-foodstock">
        </div>
        <div v-if="!catalogUpdated && timerActive" class="d-none d-md-block foodstock-timer">
          <countdown />
        </div>
      </div>
      <div class="d-inline-flex justify-content-end align-items-center gap-2">
        <nav class="nav" @click="toggleSearchMenu">
          <div class="search-icon-wrapper text-center">
            <i class="fa fa-search"></i>
          </div>
        </nav>
        <nav @click="toggleCart()">
          <div class="cart-icon-wrapper yellow text-center">
            <div class="cart-badge text-white">
              <p>{{itemsInCart}}</p>
            </div>
            <i class="fa-solid fa-shopping-basket text-white" style="font-size: 1.3rem;"></i>
          </div>
        </nav>
      </div>
      <div class="d-inline-flex justify-content-end align-items-center">
        <select v-model="language" @change="changeLanguage" class="form-select language">
          <option value="fi">FI</option>
          <option value="en">EN</option>
        </select>
      </div>
    </div>
    <div v-if="!catalogUpdated && timerActive" class="d-block d-md-none foodstock-timer mobile">
      <countdown />
    </div>
  </div>
</template>
<script>
import i18n from '../i18n'
import moment from 'moment'
import Countdown from '@/components/general/Countdown'
import CatalogUpdateCountdown from "../mixins/CatalogUpdateCountdown";
export default {
  data () {
    return {
      language: 'fi'
    }
  },
  created () {
    this.language = localStorage.getItem('language') || 'fi'
    i18n.locale = this.language
    moment.locale(this.language)
  },
  components: { Countdown },
  mixins: [ CatalogUpdateCountdown ],
  computed: {
    isCategoryPage () {
      return this.$route.name === 'categories'
    },
    itemsInCart () {
      return this.$store.getters['Cart/ITEMS_IN_CART']
    },
    isMenuOpen () {
      return  this.$store.getters['NavigationDrawer/MENU_OPEN']
    },
    timerActive () {
      return this.$store.getters['Catalog/TIMER_ACTIVE']
    },
    catalogUpdated () {
      return this.$store.getters['Catalog/CATALOG_UPDATED']
    }
  },
  methods: {
    toggleSideMenu() {
      this.$store.commit('Search/CLOSE_SEARCH');
      // if cart is open close it
      if (this.$store.getters['NavigationDrawer/CART_OPEN']) {
        this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
      }
      // if order drawer is open, close it
      if (this.$store.getters['NavigationDrawer/ORDER_OPEN']) {
        this.$store.dispatch('NavigationDrawer/TOGGLE_ORDER_TEMPLATE')
      }
      this.$store.dispatch('NavigationDrawer/TOGGLE_MENU', this.isCategoryPage)
    },
    toggleSearchMenu() {
      // if cart is open close it
      if (this.$store.getters['NavigationDrawer/CART_OPEN']) {
        this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
      }
      // if order drawer is open, close it
      if (this.$store.getters['NavigationDrawer/ORDER_OPEN']) {
        this.$store.dispatch('NavigationDrawer/TOGGLE_ORDER_TEMPLATE')
      }
      this.$store.commit('Search/TOGGLE_SEARCH')
    },
    toggleCart() {
      if (document.documentElement.clientWidth < 768) {
        if (this.$store.getters['NavigationDrawer/MENU_OPEN']) {
          this.$store.dispatch('NavigationDrawer/TOGGLE_MENU')
        }
        if (this.$store.getters['NavigationDrawer/CART_OPEN']) {
          document.querySelector('body').classList.remove('body-overflow-hidden');
        } else {
          document.body.className = 'body-overflow-hidden';
        }
        this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
      }
    },
    goToFrontPage () {
      if (this.$route.name === 'shop') {
        return;
      }
      if (this.$store.getters['NavigationDrawer/MENU_OPEN']) {
        this.$store.dispatch('NavigationDrawer/TOGGLE_MENU')
      }
      if (this.$store.getters['NavigationDrawer/CART_OPEN']) {
        this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
      }
      if (this.$store.getters['NavigationDrawer/ORDER_OPEN']) {
        this.$store.dispatch('NavigationDrawer/TOGGLE_ORDER_TEMPLATE')
      }
      this.$router.push('/shop')
    },
    changeLanguage () {
      localStorage.setItem('language', this.language)
      i18n.locale = this.language
      moment.locale(this.language)
    }
  }
}
</script>
<style lang="scss">
@import '../assets/scss/variables';

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.nav-container {
  max-width: $desktop-max-width;
  margin: 0 auto;

  .navbar-text {
    margin-top: -3px;
  }
}

.hamburger-container {
  height: 100%;
  width: 4rem;
  border-right: 2px solid white;

  .slide {
    transition: 1s;
    left: 0;
  }
}

.foodstock-navbar-right {
  padding-right: 6em;
}

.search-icon-wrapper {
  cursor: pointer;
}

@media(max-width: 400px) {
  .navbar-text:first-child {
    margin-right: 0.2rem !important;
  }
}
</style>
