<template>
    <div :key="item.price_id" class="row px-2">
        <div class="col p-0 item-name">
            <p class="mb-1">{{ parseDescriptionLocale(item) }}</p>
            <p v-if="extraInfo" class="small mb-0">{{ $t('cart.product_id') }}: {{ item.id }}</p>
            <p v-if="extraInfo" class="small mb-0">{{ $t('cart.price') }}: {{ totalPrice | currency }} ({{ $t('cart.incl_vat') }})</p>
            <p v-if="extraInfo" class="small mb-0">{{$t('cart.amount')}}: {{ item.amount }}</p>
        </div>
        <hr class="w-100 my-2"/>
    </div>
</template>

<script>
    export default {
        props: ['item', 'extraInfo'],
        methods: {
            parseDescriptionLocale (item) {
                const localizedField = `description_${this.$i18n.locale}`
                return item[localizedField]
            }
        },
      computed: {
            additionalItemsTotalPrice () {
                let total = 0
                for (let i = 0; i < this.item.additional_items.length; i++) {
                    total += parseFloat(this.item.additional_items[i].unit_price_incl_tax) * parseInt(this.item.amount)
                }
                return total
            },
            totalPrice () {
                return this.item.price_incl_tax * this.item.amount
            },
        }
    }
</script>

<style scoped lang="scss">
    @import '../../assets/scss/variables';
</style>
