<template>
    <div class="success__notification-wrapper shadow text-center">
        <strong class="text-success pt-4">{{ $t('notification.success') }}</strong>
    </div>
</template>

<script>
    export default {
        name: "SuccessNotification"
    }
</script>

<style scoped>
    .success__notification-wrapper {
        display: none;
        z-index: 9999;
        width: 300px;
        height: 50px;
        left: 10px;
        top: 70px;
        position: absolute;
        background-color: white;
        border-radius: 5px;
    }
</style>