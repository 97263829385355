<template>
  <div ref="modalOrderAgain" class="modal" id="modal-center" tabindex="-1" aria-labelledby="modal-center-label"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center py-4">
          <div class="row">
            <div class="col-12">
              <strong v-if="itemsNotFound.length === 0">{{ $t('modal.items_successfully_added') }}</strong>
              <strong v-else>{{ $t('modal.info_items_not_added') }}</strong>
            </div>

            <div v-if="itemsNotFound.length > 0" class="col-12 text-center">
              <p v-for="(item, i) in itemsNotFound" :key="i" class="text-danger mb-0">
                {{ item.product ? item.product.description : '' }}
              </p>
            </div>

            <div class="col-12 mt-3">
              <p>{{ $t('modal.add_more_items') }}</p>
            </div>

            <div class="col-12 mt-1">
              <div class="d-flex justify-content-center">
                <button class="btn btn-primary confirm-button foodstock-btn order-again" @click="closeOrderAgainModal()">{{
                  $t('button.ok') }}</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

    export default {
      data() {
        return {
          bsModal: null
        }
      },
      mounted() {
        this.bsModal = new Modal(this.$refs.modalOrderAgain);
      },
        created () {
            if (this.$route.params.orderAgain) {
                this.$nextTick(() => {this.bsModal.show()});
            }
        },
        methods: {
            closeOrderAgainModal () {
                // close modal
                this.bsModal.hide();
            },
        },
        computed: {
            itemsNotFound () {
                return this.$store.getters['ItemsNotFound/ITEMS']
            },
        }
    }
</script>

<style scoped></style>
