<template>
    <div v-show="isLoggedIn" class="mobile-bottom-nav d-md-none">
        <div class="row">
            <div class="col">
                <i class="fa-solid fa-search"></i>
            </div>
            <div class="col">
                <i class="fa-solid fa-list"></i>
            </div>
            <div class="col">
                <i class="fa-solid fa-clipboard"></i>
            </div>
            <div class="col">
                <i class="fa-solid fa-copy"></i>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        computed: {
            isLoggedIn () {
                return this.$store.getters['Auth/IS_LOGGED_IN']
            }
        }
    }
</script>

<style scoped></style>