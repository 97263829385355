<template>
  <div>
    <div class="col-12 p-sm-0">
      <div class="card text-dark">
        <transition name="fade" appear>
          <div class="card-body">
            <ul class="list-group px-0">
              <category-item :catalog="catalog" />
            </ul>
            <div class="col-12 d-none d-md-inline-block my-3">
              <button class="btn btn-outline-secondary btn-sm" data-bs-toggle="popover" data-bs-trigger="hover"
                data-bs-content="$t('category.download_excel')" @click="initExportExcel()">
                <i class="fa-solid fa-download"></i> {{ $t('menu.load_product_list') }}
              </button>
            </div>
          </div>
        </transition>
        <div class="primary action">
          <button class="btn btn-primary foodstock-btn d-md-none w-100" @click="toggleCart()">
            {{ $t('button.continue_to_cart') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryItem from './CategoryItem'
import * as XLSX from 'xlsx'

export default {
  components: {CategoryItem},
  data () {
    return {
      loading: false
    }
  },
  created () {
    if (!this.$store.getters['Catalog/GET_CATALOG']) {
      this.$store.dispatch('Catalog/INIT_CATALOG')
    }
  },
  methods: {
    initExportExcel () {
      this.$store.dispatch('Catalog/INIT_PRODUCTS')
          .then(() => this.exportExcel())
    },
    exportExcel () {
      var ws = XLSX.utils.aoa_to_sheet(this.catalogToExcel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Foodstock - Catalog");
      XLSX.writeFile(wb, this.excelFileName);
    },
    toggleCart () {
      this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
    },
  },
  computed: {
    catalog () {
      return this.$store.getters['Catalog/GET_CATALOG'];
    },
    catalogToExcel () {
      return this.$store.getters['Catalog/GET_PRODUCTS_TO_EXCEL']
    },
    excelFileName () {
      let date = new Date;
      return `Foodstock-catalog-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.xlsx`
    },
  }
}
</script>

<style scoped lang="scss">
.checked {
  color: forestgreen;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.own-list {
  &:first-child {
    border-top: none;
  }
}

.stacked-icon-wrapper {
  position: relative;
}

.stacked-icon {
  position: absolute;
  left: 14px;
  top: 15px;
}
</style>
