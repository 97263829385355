<template>
    <div class="row mb-2">

        <div class="col-12 px-2">
            <strong>{{ $t('cart.delivery_date') }}</strong>
        </div>

        <div class="col-12 px-1 mb-3">

            <v-date-picker v-model="selectedDate" :locale="{ id: 'fi', masks: { input: 'DD.MM.YYYY' } }"
                :available-dates='sortedGroup.delivery_dates_options'
                :popover="{ placement: 'bottom-left', visibility: 'click' }">
                <input type='text' class="vue-date-picker form-control" readonly
                    :value='parseDateToString(selectedDate)'>
            </v-date-picker>

            <div v-if="previousDeliveryDates.includes(userSelectedDate)" class="p-0">
                <div class="card-body justify-content-start vt-notification vt-theme-warning my-3">
                    <h5 class="card-title pb-2 w-100">{{ $t('cart.duplicate_delivery_dates_title') }}</h5>
                    <p class="card-text">{{ $t('cart.duplicate_delivery_dates_body_1') }}{{
                        userSelectedDate
                        }}{{ $t('cart.duplicate_delivery_dates_body_2') }}</p>
                </div>
            </div>
            <div v-if="amountsNotValid" class="p-0">
                <div class="card-body justify-content-start vt-notification vt-notification vt-theme-error my-3">
                    <h5 class="card-title pb-2 w-100">{{$t('cart.product_amount_zero_title')}}</h5>
                    <p class="card-text">{{$t('cart.product_amount_zero_body')}}</p>
                </div>
            </div>
        </div>

        <div class="col-12">
            <cart-item v-for="item in sortedGroup.items" :extraInfo="extraInfo" :item="item" :key="item.product_id" />
        </div>

        <div v-if="sortedGroup.auto_add_products && sortedGroup.auto_add_products.length > 0" class="col-12">
            <div class="autoadd">
                <i class="fa-solid fa-robot" style="font-size: 1.1rem;"></i> <span class="autoadd-title">{{
                    $t('cart.auto_added_items') }}</span>
            </div>
            <auto-add-item v-for="item in sortedGroup.auto_add_products" :extraInfo="extraInfo" :item="item"
                :key="item.product_id" />
        </div>

    </div>
</template>

<script>
import CartItem from './CartItem'
import AutoAddItem from "./AutoAddItem";

export default {
    props: ['sortedGroup', 'index', 'extraInfo'],
    data() {
        return {
            userSelectedDate: null,
            selectedDateChanged: false,
            myDate: new Date,
            previousDeliveryDates: []
        }
    },
    components: {CartItem, AutoAddItem},
    created() {
        this.selectedDate = this.sortedGroup.delivery_date ? this.sortedGroup.delivery_date : this.sortedGroup.delivery_dates_options[0].start
    },
    mounted() {
        this.previousDeliveryDates = [...new Set(JSON.parse(localStorage.getItem('orders')).flatMap(order => order.deliveries.map(delivery => delivery.delivery_date)))]
    },
    computed: {
        selectedDate: {
            get: function() {
                if (this.selectedDateChanged) {
                    return this.userSelectedDate
                } else {
                    return this.sortedGroup.delivery_dates_options[0].start
                }
            },
            set: function (date) {
                this.selectedDateChanged = true;
                this.userSelectedDate = this.$moment(date).format('Y-MM-DD');
                this.$store.dispatch('Cart/UPDATE_DELIVERY_DATE_FOR_GROUP', { index: this.index, delivery_date: this.$moment(date).format('Y-MM-DD') })
            }
        },
        amountsNotValid() {
            return this.$store.getters['Cart/ITEM_AMOUNTS_NOT_VALID'];
        },
    },
    methods: {
        removeItem(item) {
            this.$store.dispatch('Cart/REMOVE_ITEM', item)
        },
        updateAmount(item) {
            this.$store.dispatch('Cart/UPDATE_ITEM_AMOUNT', item)
        },
        parseDateToString(date) {
            let day = this.$moment(date).format('dddd');
            let dayString = day.charAt(0).toUpperCase() + day.slice(1);
            return dayString + ' ' + this.$moment(date).format('D.M.YYYY');
        }
    }
}
</script>

<style scoped lang="scss">
.autoadd-title {
    font-weight: bold;
    font-size: 14px;
    margin-left: .5rem;
}

.card-title {
    font-size: 1rem;
    font-weight: bold;
}

.card-body {
    font-size: 0.9rem;
}
</style>
