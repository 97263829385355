let state = {
    catalogUpdateItemsNotFound: false,
    confirmOrderLoading: false,
    selectedItem: false,
    removeItemStatus: false,
    confirmOrder: false,
    addInventoryItem: false,
    orderButtonDisabled: false
};

const actions = {
    //
};

const mutations = {
    TOGGLE_CATALOG_UPDATE_ITEMS_NOT_FOUND (state) {
        state.catalogUpdateItemsNotFound = !state.catalogUpdateItemsNotFound
    },
    ENABLE_CONFIRM_ORDER_LOADING (state) {
        state.confirmOrderLoading = true
    },
    DISABLE_CONFIRM_ORDER_LOADING (state) {
        state.confirmOrderLoading = false
    },
    ACTIVATE_SELECTED_ITEM (state) {
        state.selectedItem = true
    },
    DISABLE_SELECTED_ITEM (state) {
        state.selectedItem = false
    },
    ACTIVATE_REMOVE_ITEM (state) {
        state.removeItemStatus = true
    },
    DISABLE_REMOVE_ITEM (state) {
        state.removeItemStatus = false
    },
    ACTIVATE_CONFIRM_ORDER (state) {
        state.confirmOrder = true
    },
    DISABLE_CONFIRM_ORDER (state) {
        state.confirmOrder = false
    },
    DISABLE_ADD_INVENTORY_ITEM (state) {
        state.addInventoryItem = false
    },
    DISABLE_ORDER_BUTTON (state) {
        state.orderButtonDisabled = true
    },
    ENABLE_ORDER_BUTTON (state) {
        state.orderButtonDisabled = false
    }
};

const getters = {
    CATALOG_UPDATE_ITEMS_NOT_FOUND_STATE: state => state.catalogUpdateItemsNotFound,
    CONFIRM_ORDER_LOADING: state => state.confirmOrderLoading,
    SELECTED_ITEM: state => state.selectedItem,
    REMOVE_ITEM_STATUS: state => state.removeItemStatus,
    CONFIRM_ORDER: state => state.confirmOrder,
    ADD_INVENTORY_ITEM: state => state.addInventoryItem,
    ORDER_BUTTON_DISABLED: state => state.orderButtonDisabled
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
