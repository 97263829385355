<template>

  <div class="searchform">
    <div class="searchform__wrapper">
      <div class="searchform__wrapper__search">
        <input class="form-control" :autofocus="true" :placeholder="$t('search.search')" v-model="searchKey" />
      </div>
      <div class="searchform__wrapper__searchresults">
        <search-results :search-key="searchKey"/>
      </div>
    </div>
  </div>
</template>

<script>
import SearchResults from './SearchResults'
export default {
  components: { SearchResults },
  data () {
    return {
      searchKey: ''
    }
  },
  computed: {
    show () {
      return this.$store.getters['Search/SHOW_SEARCH']
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/_variables.scss';
.searchform {
  z-index: 9999;
  &__wrapper {
    padding-top: .7rem;
    position: fixed;
    background-color: white;
    min-height: 4rem;
    top: 4rem;
    right: 12%;
    width: 30%;
    z-index: 9999;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    &__search {
      padding: 0 .5rem 0 .5rem;
    }
    /* Mobile screen */
    @media screen and (max-width: $mobile-breakpoint) {
      width: 80%;
      right: 10%;
      padding-left: 3%;
      padding-right: 3%;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
