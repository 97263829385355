<template>
    <div v-if="selectedOrderTemplate"
        :class="['order-template-wrapper', {'order-template-open': isOrderTemplateOpen, 'order-template-closed': !isOrderTemplateOpen}]">
        <div class="sideorder-template__wrapper">
            <div class="sideorder-template__header" @click="closeSideOrderTemplate()">
                <i class="fa fa-arrow-left me-3 text-primary"></i>
                <h6>{{orderHeader}}</h6>
            </div>
            <div class="sideorder-template__container">
                <order-template :selected-order-template="selectedOrderTemplate" />
            </div>
        </div>
    </div>
</template>

<script>
    import OrderTemplate from '@/components/order/template/OrderTemplate'

    export default {
        components: {OrderTemplate},
        computed: {
            isOrderTemplateOpen() {
                return this.$store.getters['NavigationDrawer/ORDER_TEMPLATE_OPEN']
            },
            selectedOrderTemplate () {
                return this.$store.getters['Order/GET_SELECTED_ORDER_TEMPLATE']
            },
            orderHeader () {
                return this.selectedOrderTemplate.name
            }
        },
        methods: {
            closeSideOrderTemplate () {
                this.$store.dispatch('NavigationDrawer/TOGGLE_ORDER_TEMPLATE')
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';

.order-template-open {
    right: 0;

    /* Mobile screen */
    @media screen and (max-width: 768px) {
        width: 95vw !important;
    }
}

.order-template-closed {
    right: -100%;
}

.sideorder-template__wrapper {

    .sideorder-template__header {
        height: 3rem;
        padding: 1rem;
        display: flex;
        background-color: $card-header-background;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .sideorder-template__container {
        padding: 1rem;
    }
}

.order-template-wrapper {
    height: 85%;
    position: fixed;
    width: 100vw;
    z-index: 100;
    top: 64px;
    background-color: white;
    transition: .2s;
    overflow-y: scroll;
}
</style>