<template>
    <div v-if="notLoginPage && catalog" :class="['cart-wrapper', {'cart-open': isCartOpen, 'cart-closed': !isCartOpen}]">
        <cart />
    </div>
</template>

<script>
    import Cart from '@/components/cart/Cart'

    export default {
        components: {Cart},
        computed: {
            isCartOpen () {
                return this.$store.getters['NavigationDrawer/CART_OPEN']
            },
            notLoginPage () {
                return this.$route.path !== '/'
            },
            catalog () {
                return this.$store.getters['Catalog/GET_CATALOG'];
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';

.cart-open {
    /*width: 20vw;*/
    right: 0;

    /* Mobile screen */
    @media screen and (max-width: 768px) {
        width: 95vw !important;
    }
}

.cart-closed {
    right: -100%;
}

.cart-wrapper {
    height: 85%;
    position: fixed;
    width: 100vw;
    z-index: 999;
    top: 64px;
    background-color: white;
    transition: .2s;
    overflow-y: scroll;
}
</style>