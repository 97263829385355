<template>
    <div>
        <transition name="slide" appear>
            <div class="autoadd">
                <div class="autoadd__container">
                    <span class="autoadd__container__title"><i class="fa-solid fa-robot" style="font-size: 1.2rem;"></i>
                        {{ $t('notification.auto_add_success') }}</span>
                    <span class="autoadd__container__close" @click="closeAutoAddInfo()">&#10005;</span>
                </div>
                <div class="autoadd__container__info">
                    {{ $t('notification.auto_added_items') }}
                </div>
                <div v-if="autoAddProducts.length > 0" class="autoadd__container__product">
                    <div v-for="(autoAddProduct, i) in autoAddProducts" :key="autoAddProduct.id">
                        <p class="small font-weight-bold m-0">{{ parseDescriptionLocale(autoAddProduct) }}, {{
                            autoAddProduct.unit_of_measure }}</p>
                        <p class="small font-weight-bold m-0">{{$t('cart.product_id')}}: {{ autoAddProduct.id }}</p>
                        <p class="small font-weight-bold m-0">{{$t('cart.sum')}}: {{ autoAddProduct.price_incl_tax |
                            currency }} <span class="small">({{$t('cart.incl_tax')}})</span></p>
                        <p class="small font-weight-bold m-0">{{$t('cart.amount')}}: {{ autoAddProduct.amount }}</p>
                        <hr v-if="autoAddProducts.length > i + 1" class="w-100">
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "AutoAddProductsAdded",
        // Close the popup automatically after 5 seconds
        mounted () {
          setTimeout(() => {
            this.closeAutoAddInfo()
          }, 5000);
        },
        methods: {
            closeAutoAddInfo () {
                this.$emit('closeAutoAddInfo')
            },
            parseDescriptionLocale (autoAddProduct) {
                const localizedField = `description_${this.$i18n.locale}`
                return autoAddProduct[localizedField]
            }
        },
        computed: {
            autoAddProducts () {
                return this.$store.getters['AutoAddProduct/GET_PRODUCTS']
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';

.autoadd {
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.6);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.6);
    width: 25rem;
    min-height: 10rem;
    left: 0;
    right: 0;
    margin: auto;
    top: 10%;
    position: fixed;
    background-color: white;
    border-left: 5px solid #28a745;
    z-index: 10;
    padding: .7rem;

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__title {
            color: #28a745;
            font-weight: bold;
        }

        &__close {
            cursor: pointer;
            font-weight: $foodstock-font-bold;
        }

        &__info {
            padding-top: .5rem;
            font-size: 14px;
        }

        &__product {
            padding-top: .5rem;
        }
    }
}

.slide-enter-active {
    transition: all .2s ease;
}

.slide-leave-active {
    transition: all .6s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-enter,
.slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
}
</style>
