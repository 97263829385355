<template>
  <div class="main-icon-container-wrapper -link">
    <div class="icon-container -plus">
      <i class="fa fa-plus fa-2x text-white"></i>
    </div>
    <div class="icon-container -pen">
      <i class="fa fa-pen fa-3x text-white"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewOrder"
}
</script>