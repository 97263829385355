import axios from 'axios'

function updateOrders(order) {
  // get orders from session storage
  let orders = localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : [];
  // add new order to orders array
  orders.unshift(order);
  // set orders back to session storage
  localStorage.setItem('orders', JSON.stringify(orders))
}

function addOrderTemplate(orderTemplate) {
  return new Promise ((resolve) => {
    let config = { headers: {'Authorization' : localStorage.getItem('access_token'), 'Accept': 'application/json'}};
    let orderTemplates = localStorage.getItem('orderTemplates') ? JSON.parse(localStorage.getItem('orderTemplates')) : [];
    orderTemplates.unshift({orderTemplate});
    localStorage.setItem('orderTemplates', JSON.stringify(orderTemplates));
    axios.post(process.env.VUE_APP_BACKEND_URL + '/api/user/order_template', {orderTemplate}, config)
      .then(response => {
        resolve(response);
        return response
      })
  })
}

function removeOrderTemplate(payload) {
  let orderTemplates = JSON.parse(localStorage.getItem('orderTemplates'));
  let index = orderTemplates.indexOf(payload);
  orderTemplates.splice(index, 1);
  localStorage.setItem('orderTemplates', JSON.stringify(orderTemplates));
}

export default {
  updateOrders,
  addOrderTemplate,
  removeOrderTemplate
}
