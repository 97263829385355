<template>
  <div ref="modalCatalogUpdateMissingItems" class="modal" id="modal-center" tabindex="-1"
    aria-labelledby="modal-center-label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center py-4">
          <div class="row">
            <div class="col-12">
              <strong>{{ $t('cart.items_missing_after_catalog_update') }}</strong>
            </div>

            <div v-if="itemsNotFound.length > 0" class="col-12 text-center">
              <p v-for="(item, i) in itemsNotFound" :key="i" class="text-danger mb-0">
                {{ item.description }}
              </p>
            </div>

            <div class="col-12 mt-1">
              <div class="d-flex justify-content-center">
                <button class="btn btn-primary confirm-button foodstock-btn" @click="closeOrderAgainModal()">{{
                  $t('button.ok') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'

    export default {
      data() {
        return {
          bsModal: null
        }
      },
      mounted() {
        // Initialize Bootstrap 5 Modal
        this.bsModal = new Modal(this.$refs.modalCatalogUpdateMissingItems)
      },
        methods: {
            closeOrderAgainModal () {
                this.$store.commit('Modal/TOGGLE_CATALOG_UPDATE_ITEMS_NOT_FOUND')
                // remove items from store
                this.$store.commit('ItemsNotFound/REMOVE_ITEMS')
                this.bsModal.hide()
            },
            show () {
              this.bsModal.show()
            }
        },
        computed: {
            itemsNotFound () {
                return this.$store.getters['ItemsNotFound/ITEMS']
            }
        }
    }
</script>

<style scoped></style>
