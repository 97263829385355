const types = {
    TOGGLE_MENU: 'TOGGLE_MENU',
    TOGGLE_CART: 'TOGGLE_CART',
    TOGGLE_ORDER_TEMPLATE: 'TOGGLE_ORDER_TEMPLATE'
};

// initial state
const state = {
    sidebarOpen: false,
    cartOpen: false,
    orderOpen: false,
    orderTemplateOpen: false,
    isCategoryPage: false,
};

// actions
const actions = {
    TOGGLE_MENU ({ commit }, payload) {
        commit(types.TOGGLE_MENU, payload)
    },
    TOGGLE_CART ({ commit }) {
        commit(types.TOGGLE_CART)
    },
    TOGGLE_ORDER_TEMPLATE ({ commit }) {
        commit(types.TOGGLE_ORDER_TEMPLATE)
    }
};

// mutations
const mutations = {
    [types.TOGGLE_MENU] (state, payload) {
        if (payload) {
            state.isCategoryPage = payload;
        } else {
            setTimeout(() => state.isCategoryPage = payload, 500)
        }
        state.sidebarOpen = !state.sidebarOpen
    },
    [types.TOGGLE_CART] (state) {
        state.cartOpen = !state.cartOpen
    },
    [types.TOGGLE_ORDER_TEMPLATE] (state) {
        state.orderTemplateOpen = !state.orderTemplateOpen
    }
};

// getters
const getters = {
    MENU_OPEN: state => state.sidebarOpen,
    CART_OPEN: state => state.cartOpen,
    ORDER_TEMPLATE_OPEN: state => state.orderTemplateOpen,
    IS_CATEGORY_PAGE: state => state.isCategoryPage
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
