<template>
    <div ref="orderAgainCartNotEmptyModal" class="modal" id="modal-center" tabindex="-1"
        aria-labelledby="modal-center-label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body text-center py-4">

                    <div class="row">
                        <div class="col-12">
                            <strong>{{$t('cart.not_empty')}}</strong>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12">
                            <p>{{$t('cart.not_empty_info')}}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex justify-content-center">
                                <button class="btn confirm-button btn-secondary"
                                    @click="closeModal()">{{$t('button.cancel')}}</button>
                                <button class="btn btn-primary confirm-button ms-2"
                                    @click="emptyCartAndOrderAgain()">{{$t('button.ok')}}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';

    export default {
        mounted() {
            this.orderAgainCartNotEmptyModal = new Modal(this.$refs.orderAgainCartNotEmptyModal)
            this.showModal()
        },
        props: {
            toggleModal: { type: Function }
        },
        methods: {
            closeModal () {
                this.orderAgainCartNotEmptyModal.hide()
                this.toggleModal()
            },
            showModal () {
                this.orderAgainCartNotEmptyModal.show()
            },
            emptyCartAndOrderAgain () {
                this.$store.dispatch('Cart/REMOVE_CART')
                    .then(() => {
                        this.$store.dispatch('Order/ORDER_AGAIN')
                            .then(() => {
                                this.$router.push({name: 'categories', params: {orderAgain: true}})
                            })
                            .finally(() => {
                                this.closeModal()
                            })
                    })
            }
        },
        computed: {
            itemsNotFoundOrderAgain () {
                return this.$store.getters['Order/GET_NOT_FOUND_ITEMS_ORDER_AGAIN']
            },
            cartIsNotEmpty () {
                return this.cartNotEmpty
            }
        },
        watch: {
            cartIsNotEmpty: function (newValue) {
                if (newValue === 1) {
                    this.$refs['modal-confirm-empty-cart'].show();
                }
            }
        }
    }
</script>

<style scoped></style>
