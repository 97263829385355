<template>

    <div class="d-flex">
        <div>
            <div class="main-icon-container-wrapper">
                <div class="icon-container">
                    <i class="fa-solid fa-shopping-basket"></i>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        //
    }
</script>
