import axios from 'axios'
import _ from 'lodash'
import CartManager from '@/managers/CartManager'
import i18n from '../../../src/i18n'

let state = {
  recipes: null,
  catalog: JSON.parse(sessionStorage.getItem('catalog')) || null,
  productsToExcel: null,
  products: sessionStorage.getItem('products') ? JSON.parse(sessionStorage.getItem('products')) : null,
  items: null,
  timerActive: false,
  catalogUpdated: false,
  selectedItem: null,
  autoAddProducts: localStorage.getItem('autoAddProducts') ? JSON.parse(localStorage.getItem('autoAddProducts')) : [],
  smallDeliveryFeeProduct: localStorage.getItem('smallDeliveryFeeProduct') ? JSON.parse(localStorage.getItem('smallDeliveryFeeProduct')) : {}
}

const actions = {
  INIT_RECIPES: (context) => {
    return axios.get('/api/user/recipes')
      .then(response => {
        let recipes = response.data.recipes
        context.commit('SET_RECIPES', recipes)
        return response
      })
  },
  INIT_CATALOG: (context) => {
    return axios.get('/api/user/catalog')
      .then(response => {
        let catalog = response.data.catalog
        // sort categories and items in it
        let sortedCatalogCategories = _.sortBy(catalog.categories, 'description_fi')
        sortedCatalogCategories.forEach(category => {
          // Extract shipping fees from product catalog
          if (category.code === 'RAHTI') {
            context.commit('SET_SMALL_DELIVERY_FEE_PRODUCT', category.products.find(product => product.id === 'P9996'))
          }
          category.products = _.sortBy(category.products, 'description_fi')
        })
        let sortedCatalog = { categories: sortedCatalogCategories }
        context.commit('SET_CATALOG', sortedCatalog)
        context.commit('SET_AUTO_ADD_PRODUCTS', catalog.auto_add_products)
        sessionStorage.setItem('catalog', JSON.stringify(sortedCatalog))
        return response
      })
  },

  /* Init products to same array, used for making catalog excel */
  INIT_PRODUCTS ({state, commit}) {
    const language = i18n.locale
    let productsToExcel = language === 'fi' ? [["Kategoria", "Tuotenumero", "Määrä", "Tuote", "Hinta (alv. 0)", "Hinta (sis. alv)"]] : [["Category", "Product number", "Amount", "Product", "Price (VAT. 0)", "Price (incl. VAT)"]]
    let products = []
    state.catalog.categories.forEach(category => {
      category.products.forEach(product => {
        const catName = language === 'fi' ? product.category_description_fi : product.category_description_en
        const prodName = language === 'fi' ? product.description_fi : product.description_en
        productsToExcel.push([catName, product.id, '', prodName, product.price_excl_tax, product.price_incl_tax])
        products.push(product)
      })
    })
    commit('SET_PRODUCTS_TO_EXCEL', productsToExcel)
    commit('SET_PRODUCTS', products)
  },

  /* This will update catalog, mainly used for every day update at 14.00 */
  UPDATE_CATALOG ({state, dispatch, rootGetters, commit}) {
    return new Promise(resolve => {
      // collect items from cart
      let cart = rootGetters['Cart/GET_SORTED_CART']

      // set cart to updating state
      dispatch('Cart/SET_UPDATING', {}, { root: true })

      // get all items from sorted cart
      let items = []
      let autoAddProductsIds = []
      cart.forEach(sortedGroup => {
        sortedGroup.items.forEach(item => {
          item.delivery_date = sortedGroup.delivery_date
          items.push(item)
        })

        // if there is auto add product in delivery group, get all ids and dont show notification when adding them in to cart
        if (sortedGroup.auto_add_products.length > 0) {
          sortedGroup.auto_add_products.forEach(autoAddProduct => autoAddProductsIds.push(autoAddProduct.id))
        }
      })
      let originalItemsAmount = items.length

      // store items in state
      commit('SET_CART_ITEMS', items)

      // update catalog
      dispatch('INIT_CATALOG')
        .then(() => dispatch('INIT_PRODUCTS'))
        .then( () => {
          dispatch('Cart/REMOVE_CART_FROM_STATE', {}, { root: true })
            .then(() => {
              // add items back to cart
              if (state.items.length > 0) {
                state.items.forEach(item => {
                  // add info to order from template
                  item.orderFromTemplate = true
                  // add auto add product ids to item
                  item.autoAddProductIds = autoAddProductsIds
                  dispatch('Cart/ADD_ITEM', item, { root: true })
                    .then()
                    .catch((response) => {
                      dispatch('ItemsNotFound/ADD_ITEM', response.product, { root: true })
                      commit('Modal/TOGGLE_CATALOG_UPDATE_ITEMS_NOT_FOUND', {}, { root: true })
                      // remove items from items array
                      let index = items.indexOf(item)
                      items.splice(index, 1)
                    })
                })
              }
              // set cart to updating state
              dispatch('Cart/SET_UPDATED', {}, { root: true })
              commit('CATALOG_UPDATED')
            })
            .then(() => {
              resolve('catalog updated')
              setTimeout(() => {
                // if cart is missing products after update, update cart on backend
                if (originalItemsAmount !== items.length) CartManager.updateCartBackend({ items: items })
              }, 1000)
            })
        })
    })
  },
  ACTIVATE_TIMER ({commit}) {
    commit('ACTIVATE_TIMER')
  },
  DISABLE_TIMER ({commit}) {
    commit('DISABLE_TIMER')
  },
  CATALOG_UPDATED ({commit}) {
    commit('CATALOG_UPDATED')
  },
  RESET_CATALOG_INDICATORS ({ commit }) {
    let catalog = state.catalog
    catalog.categories.forEach(category => {
      category.checked = false
    })
    commit('SET_CATALOG', catalog)
    sessionStorage.setItem('catalog', JSON.stringify(catalog))
  },
  RESET_SMALL_DELIVERY_FEE_PRODUCT ({ commit }) {
    commit('SET_SMALL_DELIVERY_FEE_PRODUCT', null)
  }
}

const mutations = {
  SET_RECIPES(state, payload) {
    state.recipes = payload
  },
  SET_CATALOG(state, payload) {
    state.catalog = payload
  },
  SET_PRODUCTS_TO_EXCEL (state, payload) {
    state.productsToExcel = payload
  },
  SET_PRODUCTS (state, payload) {
    sessionStorage.setItem('products', JSON.stringify(payload))
    state.products = payload
  },
  SET_CART_ITEMS (state, payload) {
    state.items = payload
  },
  ACTIVATE_TIMER (state) {
    state.timerActive = true
  },
  DISABLE_TIMER (state) {
    state.timerActive = false
  },
  CATALOG_UPDATED (state) {
    state.catalogUpdated = true
  },
  SET_SELECTED_ITEM (state, payload) {
    state.selectedItem = payload
  },
  UNSET_SELECTED_ITEM (state) {
    state.selectedItem = null
  },
  SET_AUTO_ADD_PRODUCTS (state, payload) {
    state.autoAddProducts = payload
    localStorage.setItem('autoAddProducts', JSON.stringify(payload))
  },
  SET_SMALL_DELIVERY_FEE_PRODUCT (state, payload) {
    state.smallDeliveryFeeProduct = payload
    localStorage.setItem('smallDeliveryFeeProduct', JSON.stringify(payload))
  }
}

const getters = {
  GET_RECIPES: state => state.recipes,
  GET_CATALOG: state => state.catalog,
  GET_PRODUCTS_TO_EXCEL: state => state.productsToExcel,
  GET_PRODUCTS: state => state.products,
  TIMER_ACTIVE: state => state.timerActive,
  CATALOG_UPDATED: state => state.catalogUpdated,
  GET_SELECTED_ITEM: state => state.selectedItem,
  GET_AUTO_ADD_PRODUCTS: state => state.autoAddProducts,
  AUTO_ADD_PRODUCTS_AVAILABLE: state => state.autoAddProducts.length > 0,
  GET_SMALL_DELIVERY_FEE_PRODUCT: state => state.smallDeliveryFeeProduct
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
