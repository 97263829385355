import axios from 'axios'
import router from '@/router'
import Vue from 'vue'

let state = {
  access_token: localStorage.getItem('access_token') || null,
  name: localStorage.getItem('name') || null,
  minimumOrderAmount: localStorage.getItem('minimumOrderAmount') || 0,
  userIsActive: false,
  canUseReclamation: localStorage.getItem('can_use_reclamation') === 'true' ? 1 : 0,
  popup: JSON.parse(localStorage.getItem('popup')) || null
}

const actions = {
  LOGIN : ({ dispatch, rootGetters, commit }, form) => {
    localStorage.clear()
    sessionStorage.clear()
    commit('Cart/REMOVE_SORTED_CART', {}, { root: true })
    commit('ExtraFees/REMOVE_EXTRA_FEES', {}, { root: true })
    return axios.post('/api/auth/login',
      {username: form.username, password: form.password})
      .then(response => {
        if (response.status === 200) {
          const token = 'Bearer ' + response.data.access_token
          const name = response.data.name
          const reclamationUsers = process.env.VUE_APP_ALLOWED_RECLAMATION_USERS ? process.env.VUE_APP_ALLOWED_RECLAMATION_USERS.split(',') : []
          const canUseReclamation = reclamationUsers.length ? reclamationUsers.includes(response.data.username) : true
          localStorage.setItem('name', name)
          localStorage.setItem('access_token', token)
          localStorage.setItem('can_use_reclamation', canUseReclamation)
          // Force login popup to appear even if user has seen it before
          localStorage.setItem('popupSeen', false)
          dispatch('Auth/INIT_POPUP_MESSAGES', {}, { root: true })
          commit('SET_TOKEN', token)
          commit('SET_NAME', name)
          commit('SET_RECLAMATION_PERMISSION', canUseReclamation)
          commit('USER_IS_ACTIVE', true)
          dispatch('Order/INIT_ORDERS', {}, { root: true })
          dispatch('Order/INIT_ORDER_TEMPLATES', {}, { root: true })
          commit('Search/CLOSE_SEARCH', {}, { root: true })
          dispatch('Cart/INIT_CART', {}, { root: true })
            .then((response => {
              // TODO if there is one item, and its removed from catalog, dont trigger oldCart to be true and redirect to categories page
              if (response.data.cart.items.length > 0) {
                dispatch('Catalog/INIT_CATALOG', {}, { root: true })
                  .then(() => dispatch('Catalog/INIT_PRODUCTS', {}, { root:true }))
                  .then(() => dispatch('Cart/ADD_PRODUCTS_FROM_BACKEND_TO_CART', {}, { root:true }))
                  .then(() => router.push({ name: 'categories', params: { oldCart: true } }))
              } else {
                dispatch('Catalog/INIT_CATALOG', {}, { root: true })
                  .then(() => dispatch('Catalog/INIT_PRODUCTS', {}, { root:true }))
                  .then(() => {
                    // if there is order waiting for payment
                    if (rootGetters['Order/ORDER_PAYMENT_CANCELLED']) {
                      // add items to cart
                      dispatch('Cart/ADD_PRODUCTS_FROM_ORDER_WAITING_FOR_PAYMENT_TO_CART', rootGetters['Order/ORDER_PAYMENT_CANCELLED'], { root:true })
                      router.push({ name: 'categories', params: { oldCart: true } })
                    } else {
                      router.push('/shop')
                    }
                  })
              }
            }))
          dispatch('Auth/MINIMUM_ORDER_AMOUNT', {}, { root: true })
        }
        return response
      })
  },
  LOGOUT: (context) => {
    // Clear popup from store
    context.commit('UNSET_POPUP')
    return axios.post('/api/auth/logout')
      .then(response => {
        localStorage.clear()
        sessionStorage.clear()
        context.dispatch('Catalog/RESET_SMALL_DELIVERY_FEE_PRODUCT', {}, {root: true})
        context.dispatch('NavigationDrawer/TOGGLE_MENU', {}, {root: true})
        return response
      })
  },
  UNAUTHORIZED: (context) => {
    context.commit('REMOVE_TOKEN')
    localStorage.clear()
    sessionStorage.clear()
  },
  USER_IS_ACTIVE: (context) => {
    context.commit('USER_IS_ACTIVE')
  },
  LOG_OUT_UN_AUTH({commit}) {
    localStorage.clear()
    sessionStorage.clear()
    commit('Cart/REMOVE_CART', {}, {root: true})
  },
  INIT_POPUP_MESSAGES (context) {
    axios.get('/api/user/message')
        .then(response => {
          if (response.data.message) {
            context.commit('SET_POPUP', response.data.message);
            localStorage.setItem('popup', JSON.stringify(response.data.message))
            Vue.prototype.$eventBus.$emit('popup-available')
          }
          else {
            localStorage.setItem('popup', JSON.stringify({}))
          }
        })
  },
  MINIMUM_ORDER_AMOUNT (context) {
    axios.get('/api/user/minimum-order-amount')
      .then(response => {
        context.commit('SET_MINIMUM_ORDER_AMOUNT', response.data.amount)
      }) 
  }
}

const mutations = {
  SET_TOKEN: (state, payload) => {
    state.access_token = payload
  },
  SET_NAME: (state, payload) => {
    state.name = payload
  },
  SET_RECLAMATION_PERMISSION: (state, payload) => {
    state.canUseReclamation = payload
  },
  REMOVE_TOKEN: (state) => {
    state.access_token = null
  },
  USER_IS_ACTIVE: (state, payload) => {
    state.userIsActive = payload
  },
  SET_POPUP: (state, payload) => {
    state.popup = payload
  },
  UNSET_POPUP: (state) => {
    state.popup = {}
  },
  SET_MINIMUM_ORDER_AMOUNT: (state, payload) => {
    if (payload) {
      state.minimumOrderAmount = payload.amount
      localStorage.setItem('minimumOrderAmount', payload.amount)
    }
    else {
      state.minimumOrderAmount = 0
      localStorage.setItem('minimumOrderAmount', 0)
    }
  }
}

const getters = {
  IS_LOGGED_IN: state => state.access_token !== null,
  GET_TOKEN: state => state.access_token,
  GET_NAME: state => state.name,
  USER_IS_ACTIVE: state => state.userIsActive,
  GET_RECLAMATION_PERMISSION: state => state.canUseReclamation,
  GET_POPUP: state => state.popup,
  GET_MINIMUM_ORDER_AMOUNT: state => state.minimumOrderAmount
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
