import axios from 'axios'

export default
{
  beforeCreate () {
    const token = localStorage.getItem('access_token');
    if (!token) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    } else {
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/auth/me', {}, {headers: {'Authorization': token, 'Accept': 'application/json'}})
    }
  },
}
