import Vue from 'vue'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
if (process.env.NODE_ENV === 'production') {
  // Here goes the DSN
  Raven
    .config('https://97e1a797e5f944ec8a4186687f98f85f@o4507566181974016.ingest.de.sentry.io/4507881705111632', {
      // We also track the project's release to benefit from some of Sentry's advanced features
      environment: process.env.VUE_APP_ENVIRONMENT,
      allowSecretKey: true
    })
    .addPlugin(RavenVue, Vue)
    .install()
}

import App from './App.vue'
import '@babel/polyfill'
import router from './router'
import store from './store/store'
import moment from 'moment'
import _ from 'lodash'
moment.locale('fi')
import VCalendar from 'v-calendar'
import VuePusher from 'vue-pusher'

/* Event bus */
const EventBus = new Vue()

import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap5 stuff
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


/* Import custom css */
import '@/assets/scss/custom.scss'

/* Import Vue Toastify */
import VueToastify from 'vue-toastify'

Vue.use(VueToastify, {
  position: 'top-center',
  errorDuration: 10000,
  draggable: false,
  theme: 'light'
})

/* Axios */
import Axios from 'axios'
Axios.defaults.headers.post['Accept'] = 'application/json'
Axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
Axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('access_token')
    
    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

Vue.prototype.$http = Axios
Vue.prototype.$moment = moment
Vue.prototype.$_ = _
Vue.prototype.$eventBus = EventBus
Vue.prototype.$raven = Raven

/* Import custom components */
import MobileBottomNav from '@/components/MobileBottomNav.vue'
import Navbar from '@/components/Navbar.vue'
import SideMenu from '@/components/navigationDrawers/SideMenu.vue'
import SideCart from '@/components/navigationDrawers/SideCart.vue'
import SideOrderTemplate from '@/components/navigationDrawers/SideOrderTemplate.vue'
import SuccessNotification from '@/components/notification/SuccessNotification.vue'
import ErrorNotification from '@/components/notification/ErrorNotification.vue'
import CartNotEmptyConfirmModal from '@/components/modals/CartNotEmpty'
import SearchForm from '@/components/search/SearchForm'
import { VueRecaptcha } from 'vue-recaptcha';

/* Localization */
import i18n from './i18n'

/* Components */
Vue.component('mobile-bottom-nav', MobileBottomNav)
Vue.component('navbar', Navbar)
Vue.component('side-menu', SideMenu)
Vue.component('side-cart', SideCart)
Vue.component('side-order-template', SideOrderTemplate)
Vue.component('success-notification', SuccessNotification)
Vue.component('error-notification', ErrorNotification)
Vue.component('cart-not-empty-confirm-modal', CartNotEmptyConfirmModal)
Vue.component('search-form', SearchForm)
Vue.component('vue-recaptcha', VueRecaptcha)

/* Global mixins */
import ConfirmRoute from './mixins/ConfirmRoute'
Vue.mixin(ConfirmRoute)

/* Filters */
Vue.filter('currency', function (value) {
  value = value || 0
  return parseFloat(value).toFixed(2) + ' €'
})
Vue.filter('basic_date', function (value) {
  if (!value) return ''
  return moment(value).format('D.M.YYYY')
})
Vue.filter('basic_date_and_time', function (value) {
  if (!value) return ''
  return moment(value).format('H:mm D.M.YYYY')
})
Vue.filter('shortened_time', function (value) {
  if (!value) return ''
  return moment(value).format('H:mm')
})
Vue.filter('basic_time', function (value) {
  if (!value) return ''
  return moment(value).format('H:mm:ss')
})
Vue.filter('date_with_day_string', function (value) {
  if (!value) return ''
  let day = moment(value).format('dddd')
  let dayString = day.charAt(0).toUpperCase() + day.slice(1)
  
  return dayString + ' ' + moment(value).format('D.M.YYYY') + ' ' + moment(value).format('H:m')
})
Vue.filter('date_with_day_string_no_hours', function (value) {
  if (!value) return ''
  let day = moment(value).format('dddd')
  let dayString = day.charAt(0).toUpperCase() + day.slice(1)
  
  return dayString + ' ' + moment(value).format('D.M.YYYY')
})
Vue.filter('round', function (value) {
  return value ? Math.round(value * 100) / 100 : 0
})

router.beforeEach((to, from, next) => {
  // logged in user authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['Auth/IS_LOGGED_IN']) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  }
  // not logged in user
  else if (to.matched.some(record => record.meta.requiresVisitor)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters['Auth/IS_LOGGED_IN']) {
      next({
        path: '/shop',
      })
    } else {
      next()
    }
  }
  // prevent to show order success page straight from url
  else if (to.matched.some(record => record.meta.orderSuccess)) {
    if (!store.getters['Order/ORDER_SUCCESS']) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  }
  else {
    next() // make sure to always call next()!
  }
})
Vue.config.productionTip = false

Vue.use(VCalendar)
Vue.use(VuePusher, {
  api_key: process.env.VUE_APP_PUSHER_APP_KEY,
  options: {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
  }
})

Axios.interceptors.response.use(undefined, error => {
  Raven.captureException(error.response)
  const status = error.response ? error.response.status : undefined
  switch (status) {
    case 401: // Unauthorized
      // Skip error message if endpoint was /me (called in every page load, expected)
      if (error.response.config.url.indexOf('api/auth/me') > -1) {
        return Promise.resolve(error)
      }
      // Failed login attempt
      if (router.currentRoute.path === '/' && error.response.data.error !== 'Account banned') {
        store.dispatch('Messaging/SHOW_MESSAGE', {
          type: 'error',
          title: i18n.t(error.response.data.error),
          body: i18n.t('Username or password was incorrect')
        })
        return false
      }
      // Banned
      if (router.currentRoute.path === '/' && error.response.data.error === 'Account banned') {
        store.dispatch('Messaging/SHOW_MESSAGE', {
          type: 'error',
          title: i18n.t(error.response.data.error),
          body: i18n.t('You have been banned, please contact administration')
        })
        return false
      }
      // User logged out mid browsing the store
      store.dispatch('Messaging/SHOW_MESSAGE', {
        type: 'error',
        title: i18n.t('Error'),
        body: i18n.t('You were logged out')
      })
      router.push('/')
      store.dispatch('Auth/UNAUTHORIZED')
      return Promise.reject(error)
    // Internal server error, always inform user
    case 500:
    case 501:
    case 502:
    case 504:
      switch (router.currentRoute.name) {
        case 'categories':
          // Show different error message if adding products to cart
          if (error.response.config.url.indexOf('api/user/cart/item') > -1) {
            store.dispatch('Messaging/SHOW_MESSAGE', {
              type: 'error',
              title: i18n.t('Network disrupted'),
              body: i18n.t('Check your shopping cart if you log out before ordering, you may continue ordering as normal')
            })
          }
          else {
            store.dispatch('Messaging/SHOW_MESSAGE', {
              type: 'error',
              title: i18n.t('Internal server error'),
              body: i18n.t('A server error occurred. Try again and if issue persists, contact service desk.')
            })
          }
          break
        default:
          store.dispatch('Messaging/SHOW_MESSAGE', {
            type: 'error',
            title: i18n.t('Internal server error'),
            body: i18n.t('A server error occurred. Try again and if issue persists, contact service desk.')
          })
      }
      return Promise.reject(error)
    case 503:
      store.dispatch('Auth/UNAUTHORIZED')
      router.replace({name: 'maintenance', params: {data: error.response.data}})
      break
    case 422:
      // TODO handle and show user the error
      return Promise.reject(error)
    default:
      return Promise.reject(error)
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
