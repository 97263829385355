<template>
    <div ref="confirmOrderModal" class="modal" id="modal-center" tabindex="-1" aria-labelledby="modalCenterLabel"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center py-4">
                    <div class="row">
                        <div class="col-12 mb-1">
                            <strong>{{$t('cart.order_items')}}?</strong>
                        </div>
                        <div v-if="orderError" class="col-12 mb-2">
                            <span class="text-danger">{{$t('modal.error_sending_order')}}.</span>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="d-flex justify-content-center">
                                <button class="btn btn-secondary" @click="cancelOrder()">{{$t('button.cancel')}}</button>
                                <button class="btn btn-primary confirm-button ms-2" id="confirmOrderButton" :disabled="loading" @click="confirmOrder()">
                                    <span v-if="loading">
                                        <div class="spinner-grow spinner-grow-sm" role="status"></div>
                                    </span>
                                    <span v-else>
                                        {{$t('button.ok')}}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { Modal } from 'bootstrap'
    export default {
        data() {
            return {
                confirmOrderModal: null
            }
        },
        mounted() {
            // Close side cart
            if (this.$store.getters['NavigationDrawer/CART_OPEN']) {
                this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
            }
            this.confirmOrderModal = new Modal(this.$refs.confirmOrderModal)
            this.show()
        },
        computed: {
            loading () {
                return this.$store.getters['Modal/CONFIRM_ORDER_LOADING']
            },
            orderError () {
                return this.$store.getters['Order/ORDER_ERROR']
            }
        },
        methods: {
            show () {
                this.$store.dispatch('Order/DISABLE_ORDER_ERROR')
                this.confirmOrderModal.show()
            },
            cancelOrder () {
                this.$store.commit('Modal/ENABLE_ORDER_BUTTON');
                this.hide()
            },
            hide () {
                this.$store.dispatch('Order/DISABLE_ORDER_ERROR')
                this.$store.commit('Modal/DISABLE_CONFIRM_ORDER')
                this.confirmOrderModal.hide()
            },
            confirmOrder () {
                this.$store.commit('Modal/ENABLE_CONFIRM_ORDER_LOADING')
                this.hide()
                this.$emit('confirm-order')
            }
        },
    }
</script>

<style scoped>
.btn-secondary {
    color: white;
    background: #6c757d linear-gradient(180deg, #828a91, #6c757d) repeat-x;
    margin-right: 8px;
}

.foodstock-btn {
    width: 6rem;
}
</style>
