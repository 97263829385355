import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

/* Import modules */
import Auth from './modules/Auth';
import Catalog from './modules/Catalog'
import Cart from './modules/Cart'
import Order from './modules/Order'
import NavigationDrawer from './modules/NavigationDrawer'
import Notifications from './modules/Notifications'
import ItemsNotFound from './modules/ItemsNotFound';
import Faq from './modules/Faq'
import Modal from './modules/Modal'
import Payment from "./modules/Payment";
import Search from "./modules/Search";
import AutoAddProduct from "./modules/AutoAddProduct";
import WebSocket from "./modules/WebSocket";
import ExtraFees from "./modules/ExtraFees";
import Inventory from "./modules/Inventory";
import Reclamation from "./modules/Reclamation";
import Messaging from "./modules/Messaging";

export default new Vuex.Store({
  modules: {
    Auth,
    Catalog,
    Cart,
    Order,
    NavigationDrawer,
    Notifications,
    ItemsNotFound,
    Faq,
    Modal,
    Payment,
    Search,
    AutoAddProduct,
    WebSocket,
    ExtraFees,
    Inventory,
    Reclamation,
    Messaging
  }
})
