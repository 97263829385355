<template>
  <div ref="selectedItemModal" class="modal" id="modal-center" tabindex="-1" aria-labelledby="modal-center-label"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header py-1 pr-2 pl-3">
          <h5 class="modal-title" id="modal-center-label">
            {{ selectedItem !== null ? parseCategoryLocale : null }}
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="closeModal()"></button>
        </div>
        <div class="modal-body">
          <div class="mb-1 px-0">
            <strong>{{ parseDescriptionLocale }}, {{ selectedItem.unit_of_measure }}</strong>
          </div>

          <div v-if="selectedItem && !selectedItem.out_of_stock" class="row mx-0 mt-3">
            <div class="col-4 px-0">
              <div class="tooltip-amount">
                <input type="number" class="form-control add-shopping-cart-count" ref="itemAmount" v-model="amount"
                  @click="amount = ''" @keydown.enter="addToCart()" min="0" @input="inputListeners" />
                <div v-if="bigAmount" class="tooltip-amount__tooltip">
                  <div class="tooltip-amount__tooltip__title">
                    <div class="tooltip-amount__tooltip__title__text">{{ $t('notification.amount_title') }}</div>
                    <span class="tooltip-amount__tooltip__title__close" @click="bigAmount = false">&#10005;</span>
                  </div>
                  <div class="tooltip-amount__tooltip__info">
                    {{ $t('notification.amount_message') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8 px-2">
              <button class="btn btn-primary w-100" :disabled="amount <= 0" @click="addToCart()">{{
                $t('cart.add_to_cart') }}</button>
            </div>
          </div>

          <div v-if="selectedItem.notes" class="row align-items-center mt-3">
            <div class="col-12 px-0">
              <i class="fa-solid fa-info-circle text-danger"></i> {{ selectedItem.notes }}
            </div>
          </div>

          <div class="mt-3 px-0">
            <p class="mb-0 small">{{ $t('category.product_id') }}: {{ selectedItem.id }}</p>
            <p class="mb-0 small">{{ $t('category.unit_of_measure') }}: {{ selectedItem.unit_of_measure }}</p>
            <p class="mb-0 small">{{ $t('category.price') }}: {{ selectedItem.price_excl_tax | currency }} ({{
              $t('cart.excl_tax') }})</p>
            <p class="mb-0 small">{{ $t('category.price') }}: {{ selectedItem.price_incl_tax | currency }} ({{
              $t('cart.incl_tax') }})</p>
            <p class="mb-0 small">{{ $t('category.delivery_date') }}: {{ selectedItem.delivery_dates[0] | basic_date }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
  data () {
    return {
      amount: 1,
      bigAmount: false,
      selectedItemModal: null
    }
  },
  computed: {
    isDesktop () {
      return document.documentElement.clientWidth > 767
    },
    selectedItem () {
      return this.$store.getters['Catalog/GET_SELECTED_ITEM']
    },
    parseDescriptionLocale () {
      const localizedField = `description_${this.$i18n.locale}`
      return this.selectedItem[localizedField]
    },
    parseCategoryLocale () {
      const localizedField = `category_description_${this.$i18n.locale}`
      return this.selectedItem[localizedField]
    }
  },
  mounted() {
    this.selectedItemModal = new Modal(this.$refs.selectedItemModal)
    this.showModal()
  },
  methods: {
    closeModal () {
      this.selectedItemModal.hide()
      this.$store.commit('Catalog/UNSET_SELECTED_ITEM')
    },
    showModal () {
      this.amount = 1
      this.selectedItemModal.show()
    },
    addToCart () {
      const vue = this
      vue.bigAmount = false
      vue.$store.dispatch('Cart/ADD_ITEM', { amount: vue.amount, price_id: vue.selectedItem.price_id, product_id: vue.selectedItem.id })
        .finally(() => {
          vue.closeModal()
          vue.$store.commit('Catalog/UNSET_SELECTED_ITEM')
        })
    },
    inputListeners (event) {
      const amount = event.target.value
      amount >= 50 ? this.bigAmount = true : this.bigAmount = false
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';

.btn {
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #007bff;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;

  // Adding transition for a smooth effect
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    // Darkening effect (change this value as per your requirements)
    background-color: darken(#007bff, 10%);
  }
}

.tooltip-amount {
  position: relative;

  &__tooltip {
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.6);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.6);
    font-size: 14px;
    position: absolute;
    background-color: white;
    min-height: 4rem;
    width: 25rem;
    top: 45px;
    left: -47px;
    z-index: 1;
    border-left: 5px solid tomato;
    padding: .7rem;

    @media screen and (max-width: $mobile-breakpoint) {
      width: 17rem;
      left: 0;
    }

    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: .5rem;

      &__text {
        color: tomato;
        font-size: 14px;
        font-weight: $foodstock-font-bold;
      }

      &__close {
        cursor: pointer;
        font-weight: $foodstock-font-bold;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -88px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent white transparent;

      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: -94px;
      }

      @media screen and (max-width: $mobile-breakpoint-small) {
        margin-left: -104px;
      }
    }
  }
}
</style>
