<template>
    <div class="mb-5">
        <div class="row">
            <div class="col-12">
                <div class="card">

                    <!-- HEADER -->
                    <h6 slot="header" class="card-header mb-0">
                        <span>
                            <i class="fa-solid fa-book me-3"></i>
                        </span>
                        {{ $t('campaign.recipes') }}
                    </h6>

                    <!-- BODY -->
                    <div class="card-body">
                        <!-- SELECT A RECIPE -->
                        <div class="card-text fw-bold">
                            {{ $t('campaign.choose-recipe') }}
                        </div>

                        <select class="form-select" v-model="recipe" id="recipe-selector"
                            @change="changeRecipe($event)">
                            <option :value="null">{{ $t('campaign.no-selected-recipe') }}</option>
                            <option :key="index" v-for="(recipe, index) in recipes" :value="recipe.id">
                                {{ recipe.name }}
                            </option>
                        </select>

                        <template v-if="recipe">
                            <!-- RECIPE NAME AND AMOUNT -->
                            <div class="row d-flex flex-wrap justify-content-between font-weight-bold mt-5 mb-2">
                                <div class="col-12 col-md-7" id="recipe-name">{{ getRecipeName }}{{
                                    $t('campaign.pizza-type') }}</div>
                                <div class="col-12 col-md-4 p-3 p-md-0 d-flex justify-content-center">
                                    <div id="recipe-quantity" class="d-flex align-items-center">
                                        <button class="qty-changer" @click="decrement" id="decrement-recipe">
                                            <i class="fa-solid fa-minus fa-sm"></i>
                                        </button>
                                        <input v-model="amount" type="number" disabled
                                            class="form-control num text-center" />
                                        <button class="qty-changer" @click="increment" id="increment-recipe">
                                            <i class="fa-solid fa-plus fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <!-- INGREDIENTS HEADER -->
                            <div class="row m-0 px-2 py-2">
                                <div class="p-0 col-8 col-sm-6 col-md-4 text-uppercase ingredient-header">
                                    {{ $t('campaign.product') }}
                                </div>
                                <div
                                    class="p-0 col-4 col-sm-2 col-md-2 text-sm-center text-uppercase ingredient-header">
                                    {{ $t('campaign.amount') }}
                                </div>
                                <div class="col-3 d-md-block d-none" />
                                <div class="col-3 text-uppercase ingredient-header d-none d-md-block">
                                    {{ $t('campaign.to-cart') }}
                                </div>
                            </div>

                            <!-- INGREDIENTS LISTING -->
                            <div :key="index"
                                v-for="(ingredient, index) in recipes.find(recipe => recipe.id === this.recipe).ingredients"
                                class="list-group-item px-2 py-3 item-rows"
                                :id="'ingredient-' + ingredient.product_code">
                                {{ selectedProductAmounts.product_code }}
                                <div class="row m-0">

                                    <!-- Product name -->
                                    <div class="col-8 col-sm-6 col-md-4 product-description">
                                        {{ getIngredientName(ingredient.product_code) }}
                                    </div>

                                    <!-- Exact product amount, i.e. "1.1 LTK" -->
                                    <div class="col-4 col-sm-2 col-md-2 p-0 text-sm-center align-self-center amount-needed"
                                        v-html="getExactAmountNeeded(ingredient.product_code, ingredient.portion_size, ingredient.package_size)" />

                                    <div
                                        class="col-12 col-sm-4 col-md-3 mt-3 mt-sm-0 align-self-center justify-content-end justify-content-sm-center d-flex align-items-center">
                                        <!-- Decrement button -->
                                        <div v-if="isValidProduct(ingredient.product_code)"
                                            class="pl-2 order-2 order-sm-0">
                                            <button class="item-quantity justify-content-center"
                                                :id="'decrement-ingredient-' + ingredient.product_code"
                                                @click="decrementItemQty(ingredient)">
                                                <i class="fa-solid fa-minus  fa-sm"></i>
                                            </button>
                                        </div>

                                        <!-- Increment button -->
                                        <div v-if="isValidProduct(ingredient.product_code)"
                                            class="pl-2 order-3 order-sm-0">
                                            <button class="item-quantity justify-content-center"
                                                :id="'increment-ingredient-' + ingredient.product_code"
                                                @click="incrementItemQty(ingredient)">
                                                <i class="fa-solid fa-plus fa-sm"></i>
                                            </button>
                                        </div>

                                        <!-- Formatted total amount after customer modifications with colors -->
                                        <div v-if="isValidProduct(ingredient.product_code)"
                                            class="order-0 pr-3 pr-sm-0">
                                            <input :id="ingredient.product_code + '-amount'" style="width: 45px;"
                                                class="formatted-item-quantity justify-content-center text-right text-md-center"
                                                :class="{
                            positive: Math.sign(selectedProductAmounts[getIndex(ingredient.product_code)].amount) === 1,
                            negative: Math.sign(selectedProductAmounts[getIndex(ingredient.product_code)].amount) === -1 }"
                                                v-if="selectedProductAmounts[getIndex(ingredient.product_code)]"
                                                disabled
                                                v-model="selectedProductAmounts[getIndex(ingredient.product_code)].formattedAmount" />
                                        </div>
                                    </div>
                                    <!-- Amount calculations row -->
                                    <div v-if="isValidProduct(ingredient.product_code)"
                                        class="row col-12 col-sm-12 col-md-3 mt-md-0 mt-4 m-0 align-self-center justify-content-center pl-0">
                                        <div
                                            class="col-7 text-right text-uppercase font-weight-bold ingredient-header d-block d-md-none">
                                            {{ $t('campaign.to-cart') }}:
                                        </div>
                                        <!-- The actual amount going into basket -->
                                        <div v-if="isValidProduct(ingredient.product_code)"
                                            class="col-4 col-md-9 pl-2 pl-md-3 align-self-center font-weight-bold to-cart-amount">
                                            <span class="border-bottom pb-1 border-primary"
                                                v-html="getRecalculatedTotalAmount(ingredient.portion_size, ingredient.package_size, ingredient.product_code, true)" />
                                        </div>

                                        <!-- Checkbox -->
                                        <div class="col-1 col-md-3 p-0 text-right align-self-center"
                                            v-if="isValidProduct(ingredient.product_code)">
                                            <input class="vertical-align-middle" type="checkbox"
                                                checked="isValidProduct(ingredient.product_code)"
                                                v-if="isValidProduct(ingredient.product_code)"
                                                v-model="ingredient.selected"
                                                @input="toggleRow($event, ingredient.product_code)"
                                                :id="'checbox-' + ingredient.product_code"
                                                :productId="ingredient.product_code" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col text-right">
                                    <button class="btn btn-outline-secondary reset-ingredients"
                                        :disabled="selectedProducts && selectedProducts.length <= 0 || false"
                                        @click="resetIngredientChoices">
                                        {{ $t('campaign.reset-choices') }}
                                    </button>
                                </div>
                            </div>

                            <!-- PRICE ROW -->
                            <div class="row mt-3 align-items-center">
                                <div class="col-12 pr-1">
                                    <strong>{{ $t('campaign.total') }}</strong>
                                </div>
                                <div v-if="recipe" class="col-12 col-md-6 text-left" v-html="getTotalPrice()"
                                    id="total-amount" />
                                <div class="col-12 col-md-6 text-right mt-3 mt-md-0">
                                    <button class="btn btn-primary foodstock-btn"
                                        :disabled="selectedProducts && selectedProducts.length <= 0 || false"
                                        @click="addManyToCart">
                                        {{ $t('cart.add_to_cart') }}
                                    </button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: ['recipes'],
    data() {
        return {
            defaultAmount: 20,
            amount: this.defaultAmount,
            min: 5,
            recipe: null,
            step: 5,
            selectedProducts: [],
            selectedProductAmounts: [],
            recipeIngredients: []
        }
    },
    created() {
        const ingredientIds = this.recipes.flatMap(recipe => recipe.ingredients.map(ingredient => ingredient.product_code)) ?? []
        this.getProductNames(ingredientIds)
    },
    computed: {
        getRecipeName() {
            return this.recipes.find(recipe => recipe.id === this.recipe).name
        },
        products() {
            return this.$store.getters['Catalog/GET_PRODUCTS']
        }
    },
    methods: {
        formatAmount(amount) {
            if (amount > 0) {
                return "+" + amount;
            } else return amount;
        },
        resetIngredientChoices() {
            // We don't reset the pizza amounts here, just the custom ingredient choices
            this.selectedProductAmounts.forEach((product) => {
                product.amount = 0;
                product.formattedAmount = 0;
                // Get the actual ingredient and use it to reset the totalAmount
                const ingredient = this.recipes.find(recipe => recipe.id === this.recipe).ingredients.find((prod) => prod.product_code === product.product_code);
                product.totalAmount = this.getRecalculatedTotalAmount(ingredient.portion_size, ingredient.package_size, ingredient.product_code, false);
            })
        },
        getIndex(id) {
            return this.selectedProductAmounts.findIndex((e) => e.product_code == id)
        },
        isValidProduct(productId) {
            const product = this.products.find((product) => (product.id === (productId).toString()) && product.out_of_stock !== 1)
            return !!product
        },
        addManyToCart() {
            const selectedRecipe = this.recipes.find(recipe => recipe.id === this.recipe);
            const productIds = this.selectedProducts;
            const ingredients = selectedRecipe.ingredients;

            productIds.forEach((value) => {
                // Check that the product exists and is not out of stock
                const product = this.products.find((product) => (product.id === (value).toString()) && product.out_of_stock !== 1)
                if (product) {
                    // Get the actual product info for this ingredient
                    const ingredient = ingredients.find((i) => i.product_code === value);
                    // Get the amount for said product from the pizza amount selection
                    const amount = this.getTotalAmount(ingredient.portion_size, ingredient.package_size, ingredient.product_code, false)
                    // Add to it the amount from what the customer has inputted with +- keys
                    const item = this.selectedProductAmounts.find((item) => item.product_code === ingredient.product_code);
                    const totalAmount = amount + item.amount;
                    // If the customer has set the custom amount to 0 but has not unselected the row, do not dispatch
                    if (totalAmount > 0) {
                        const ingredientProduct = this.products.find((product) => product.id === (ingredient.product_code).toString())
                        // Dispatch
                        this.$store.dispatch('Cart/ADD_ITEM', {
                            amount: totalAmount,
                            price_id: ingredientProduct.price_id,
                            product_id: (ingredient.product_code).toString()
                        })
                    }
                }
            });
        },
        changeRecipe(event) {
            const recipeId = event.target.value
            // Find the selected recipe and set it to active recipe
            const selectedRecipe = this.recipes.find(recipe => recipe.id.toString() === recipeId);
            this.recipe = selectedRecipe.id;

            // Set the selectedProducts array to have all the product id's of found products
            // And then map them into array
            this.selectedProducts = selectedRecipe.ingredients.filter((ingredient) => {
                const productId = ingredient.product_code.toString();
                const productFound = this.products.find((product) => product.id === productId && product.out_of_stock === 0);
                if (productFound) {
                    return productFound.id;
                }
            }).map(product => product.product_code);
            this.selectedProductAmounts = selectedRecipe.ingredients.filter((ingredient) => {
                const productId = ingredient.product_code.toString();
                const productFound = this.products.find((product) => product.id === productId);

                if (productFound) {
                    return productFound.id;
                }
            }).map(product => ({
                product_code: product.product_code,
                amount: 0,
                formattedAmount: 0,
                // Sometimes the product gets loaded slower so we have a fallback
                totalAmount: product ? this.getTotalAmount(product.portion_size, product.package_size, product.product_code, false) : 0,
            }));
            this.amount = this.defaultAmount;
        },
        toggleRow(event, id) {
            const isChecked = event.target.checked;
            if (isChecked) {
                this.selectedProducts.push(id)
            } else {
                const index = this.selectedProducts.indexOf(id);
                if (index !== -1) {
                    this.selectedProducts.splice(index, 1);
                }
            }
        },
        decrement() {
            if (this.amount - this.step >= this.min) {
                this.amount -= this.step
            }
        },
        increment() {
            this.amount += this.step
        },
        decrementItemQty(ingredient) {
            const totalAmount = this.getTotalAmount(ingredient.portion_size, ingredient.package_size, ingredient.product_code, false);
            let moddedIngredient = this.selectedProductAmounts.find((item) => item.product_code === ingredient.product_code);
            if (moddedIngredient.totalAmount > 0) {
                moddedIngredient.amount--;
                moddedIngredient.formattedAmount = this.formatAmount(moddedIngredient.amount);
                moddedIngredient.totalAmount = moddedIngredient.amount + totalAmount;
            }
        },
        incrementItemQty(ingredient) {
            const totalAmount = this.getTotalAmount(ingredient.portion_size, ingredient.package_size, ingredient.product_code, false);
            let moddedIngredient = this.selectedProductAmounts.find((item) => item.product_code === ingredient.product_code);
            moddedIngredient.amount++;
            moddedIngredient.formattedAmount = this.formatAmount(moddedIngredient.amount);
            moddedIngredient.totalAmount = moddedIngredient.amount + totalAmount;
        },
        getExactAmountNeeded(ingredientId, portion_size, package_size) {
            const totalAmount = portion_size * this.amount / package_size;
            const product = this.products.find((product) => product.id === (ingredientId).toString());
            const size = product ? ' ' + product.unit_of_measure : '';
            return Number.isInteger(totalAmount) ? totalAmount + size : (totalAmount).toFixed(1) + size
        },
        getIngredientName(ingredientId) {
            const product = this.products.find((product) => product.id === (ingredientId).toString());
            const locale = this.$i18n.locale
            let productName = product ? locale === 'fi' ? product.description_fi : product.description_en : undefined;
            if (product && product.out_of_stock) {
                return this.$t('campaign.product-out-of-stock', {id: ingredientId, productName})
            }
            const missingProductName = this.recipeIngredients.length ? this.recipeIngredients.find(i => i.product_code == ingredientId)[`description_${this.$i18n.locale}`] + ' (id: ' + ingredientId + ')' : ingredientId
            return productName || this.$t('campaign.no-product', {id: missingProductName})
        },
        async getProductNames(ingredientIds) {
            const productNames = await axios.get('/api/user/catalog/product_names', {params: { ids: ingredientIds }})
                .then(response => {
                    return response.data.products
                })
            this.recipeIngredients = productNames
        },
        getTotalAmount(portion_size, package_size, productId, showSizeType) {
            if (productId) {
                const product = this.products.find((product) => product.id === (productId).toString());
                const sizeType = product ? product.unit_of_measure : '';
                // This is a check since if it's the first recipe, the this.amount hasn't had time to load yet
                const totalAmount = portion_size * (this.amount ? this.amount : this.defaultAmount) / package_size;
                return showSizeType ? Math.ceil(totalAmount) + ' ' + sizeType : Math.ceil(totalAmount);
            }
        },
        getRecalculatedTotalAmount(portion_size, package_size, productId, showSizeType) {
            if (productId) {
                const product = this.products.find((product) => product.id === (productId).toString());
                if (product) {
                    const sizeType = product ? product.unit_of_measure : '';
                    const totalAmount = portion_size * this.amount / package_size;

                    // Making a "proper" if since it would have otherwise been a nested ternary if
                    // since we don't want to check for finding a product unless selectedProductAmounts length > 0
                    let modifiedAmount = 0;
                    if (this.selectedProductAmounts && this.selectedProductAmounts.length > 0) {
                        if (this.selectedProductAmounts.find((item) => item.product_code === productId)) {
                            modifiedAmount = this.selectedProductAmounts.find((item) => item.product_code === productId).amount;
                        }
                    }
                    let calculatedTotalAmount = totalAmount + modifiedAmount;
                    // This is to prevent the final amount dropping under zero under any circumstances
                    if (Math.ceil(calculatedTotalAmount) < 0) {
                        calculatedTotalAmount = 0;
                    }

                    return showSizeType ? Math.ceil(calculatedTotalAmount) + ' ' + sizeType : Math.ceil(calculatedTotalAmount);
                }
            }
        },
        getTotalPrice() {
            const selectedProducts = this.selectedProducts;
            const ingredients = this.recipes.find(recipe => recipe.id === this.recipe).ingredients;
            let priceExclAlv = 0;
            let priceInclAlv = 0;
            if (!selectedProducts) return '';
            selectedProducts.forEach((product) => {
                const ingredient = ingredients.find((i) => i.product_code === product);
                if (!ingredient) return 0;

                const ingredientProduct = this.products.find((product) => product.id == ingredient.product_code)
                const amount = this.getRecalculatedTotalAmount(ingredient.portion_size, ingredient.package_size, ingredient.product_code, false);
                priceExclAlv += (amount * parseFloat(ingredientProduct.price_excl_tax));
                priceInclAlv += (amount * parseFloat(ingredientProduct.price_incl_tax));
            })
            return `${priceExclAlv.toFixed(2)} (alv 0%) ${priceInclAlv.toFixed(2)} € (sis. alv)`
        }
    },
}
</script>

<style>
.inline-flex {
    display: inline-flex;
    justify-content: center;
    /* align horizontal */
    align-items: center;
    /* align vertical */
}

input#ingredient.id {
    text-align: center;
    border: 1px solid #6C757D;
}

input[type="number"] {
    -webkit-appearance: textfield !important;
    -moz-appearance: textfield !important;
    appearance: textfield !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.item-rows {
    font-size: 15px;
}

#recipe-quantity {
    border: 2px #30BDEDFF solid;
    height: 45px;
    padding: 10px;
    display: flex;
    border-radius: 55px;
    max-width: 170px;
}

.item-quantity {
    border-radius: 50%;
    display: block;
    border: 2px #30BDEDFF solid;
    background: #fff;
    color: #373A3B;
    font-weight: bold;
}

@media screen and (min-width: 768px) {
    .item-quantity {
        width: 32px;
        height: 32px;
        line-height: 25px;
    }
}

@media screen and (max-width: 767px) {
    .item-quantity {
        width: 42px;
        height: 42px;
        line-height: 35px;
    }
}

.formatted-item-quantity {
    background-color: transparent;
    border: none;
    font-weight: bold;
}

.positive {
    color: #373A3B;
}

.negative {
    color: #B11414FF;
}

.qty-changer {
    background: white;
    border: none;
    color: #373A3B;
}

.num {
    height: 100%;
    width: 39%;
    border: none;
    background-color: transparent !important;
    line-height: 100%;
}

.vertical-align-middle {
    vertical-align: middle;
}

.add-recipe-items {
    background: #30BDEDFF !important;
    border: 1px solid #30BDEDFF;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
}

@media screen and (max-width: 1023px) {
    #ingredient-header {
        font-size: smaller;
    }
}
</style>
