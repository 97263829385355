<template>
  <div class="catalog-wrapper">
    <div v-if="autoAddProducts">
      <auto-add-products-added @closeAutoAddInfo="closeAutoAddProducts" />
    </div>
    <div class="container catalog-container">
      <transition name="fade" appear>
        <div v-if="!catalog" class="row mt-5">
          <div class="col-12 text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <transition name="fade">
            <div v-if="campaignHeaderVisible && hasRecipes" class="col-12 mb-3 pt-2 px-1 text-center text-sm-start">
              <campaign-tool-header @closeCampaign="campaignHeaderVisible = false" />
            </div>
          </transition>
          <div class="col-12 mb-3 pt-2 px-1 text-center text-sm-start">
            <h1 class="foodstock-title">{{name}} - {{$t('titles.new_order')}}</h1>
          </div>
          <div class="col-sm-12 col-md-7 pl-0 px-0">
            <category-list />
          </div>
          <div class="col-md-5 d-none d-sm-none d-md-inline" v-if="!mobileView">
            <cart />
          </div>
        </div>
      </transition>
    </div>

    <!-- OLD ORDER INFO AFTER LOGIN, IF EXIST -->
    <continue-order-after-login />

    <!-- ORDER AGAIN INFO -->
    <order-again-info />

    <!-- CART NOT EMPTY WHEN MAKING NEW ORDER -->
    <cart-not-empty-confirm-modal ref="modal-cart-not-empty" />

    <!-- CATALOG ITEMS NOT FOUND -->
    <catalog-update-items-not-found ref="modal-catalog-update-missing-items" />

  </div>
</template>

<script>
import CategoryList from '@/components/shop/CategoryList'
import Cart from '@/components/cart/Cart'
import CampaignToolHeader from "@/views/shop/CampaignToolHeader";

// modals
import OrderAgainInfo from '@/components/modals/OrderAgainInfo'
import ContinueOrderAfterLogin from '@/components/modals/ContinueOrderAfterLogin'
import CatalogUpdateItemsNotFound from '@/components/modals/CatalogUpdateItemsNotFound'
import AutoAddProductsAdded from "../../components/customernotifications/AutoAddProductsAdded";

// mixins
import Authentication from "../../mixins/Authentication";

export default {
  components: {
    CampaignToolHeader,
    CategoryList,
    Cart,
    OrderAgainInfo,
    ContinueOrderAfterLogin,
    CatalogUpdateItemsNotFound,
    AutoAddProductsAdded
  },
  mixins: [ Authentication ],
  data () {
    return {
      cols: [{name: "A", key: 0}, {name: "B", key: 1}, {name: "C", key: 2}, {name: "D", key: 3}, {name: "D", key: 4}],
      campaignHeaderVisible: true,
      recipes: false
    }
  },
  created () {
    // if catalog is missing, refresh it
    if (!this.$store.getters['Catalog/GET_CATALOG']) {
      this.$store.dispatch('Catalog/INIT_CATALOG')
          .then(() => this.$store.dispatch('Catalog/INIT_PRODUCTS'))
    }

    // if cart is empty and its fresh page load, refresh catalog
    if (this.$store.getters['Cart/EMPTY_CART']) {
      this.$store.dispatch('Catalog/INIT_CATALOG')
          .then(() => this.$store.dispatch('Catalog/INIT_PRODUCTS'))
    }

    this.$store.dispatch('Catalog/INIT_RECIPES')
        .then((response) => this.recipes = response.data.recipes)
  },
  mounted () {
    if (this.$route.params.newOrder && !this.$store.getters['Cart/EMPTY_CART']) {
      this.$refs['modal-cart-not-empty'].show();
    }

    if (this.$route.params.search_result) {
      setTimeout(() => this.$store.commit('Modal/ACTIVATE_SELECTED_ITEM'), 500)
    }
  },
  methods: {
    closeModal () {
      this.$refs.oldCart.hide();
    },
    newOrder () {
      this.$store.dispatch('Cart/REMOVE_CART');
      this.$refs.oldCart.hide();
    },
    closeOrderAgainModal () {
      this.$refs['modal-order-again'].hide();
    },
    closeAutoAddProducts () {
      this.$store.commit('AutoAddProduct/DISABLE_NOTIFICATION')
    },
    updateCatalog() {
      this.$store.dispatch('Catalog/UPDATE_CATALOG')
    }
  },
  computed: {
    hasRecipes () {
      return this.recipes.length > 0
    },
    name () {
      return this.$store.getters['Auth/GET_NAME']
    },
    catalog () {
      return this.$store.getters['Catalog/GET_CATALOG']
    },
    catalogUpdateItemsNotFoundModal () {
      return this.$store.getters['Modal/CATALOG_UPDATE_ITEMS_NOT_FOUND_STATE']
    },
    autoAddProducts () {
      return this.$store.getters['AutoAddProduct/AUTO_ADD_PRODUCT_ADDED']
    },
    mobileView () {
      return document.documentElement.clientWidth < 768
    },
  },
  watch: {
    catalogUpdateItemsNotFoundModal: function (status) {
      if (status) {
        this.$refs['modal-catalog-update-missing-items'].show();
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/_variables.scss';

.catalog-wrapper {
  background-color: $foodstock-blue;
  padding-top: 5rem;
}

.foodstock-title {

  /* Mobile screen */
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.catalog-container {
  padding-bottom: 5rem;
}

.modal-continue-order {
  background-color: $foodstock-light_blue;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>
