let state = {
    show: false
};

const actions = {

};

const mutations = {
    TOGGLE_SEARCH (state) {
        state.show = !state.show
    },
    CLOSE_SEARCH (state) {
        state.show = false
    }
};

const getters = {
    SHOW_SEARCH: state => state.show
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
