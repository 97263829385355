import { render, staticRenderFns } from "./Categories.vue?vue&type=template&id=3f8a4bea&scoped=true"
import script from "./Categories.vue?vue&type=script&lang=js"
export * from "./Categories.vue?vue&type=script&lang=js"
import style0 from "./Categories.vue?vue&type=style&index=0&id=3f8a4bea&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f8a4bea",
  null
  
)

export default component.exports