<template>
  <div ref="mySaveTemplateModal" class="modal" id="modal-center" tabindex="-1" aria-hidden="true"
    data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center py-4">
          <div class="row">
            <div class="col-12 mb-1">
              <strong>{{$t('modal.give_template_name')}}:</strong>
            </div>
            <div class="col-12 px-5 mb-4 mt-2">
              <input type="text" class="form-control" v-model="templateName" @keydown.enter="saveTemplate()" autofocus />
            </div>
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-secondary" :disabled="loading"
                  @click="hide()">{{$t('button.cancel')}}</button>
                <button type="button" class="btn btn-primary ms-2" :disabled="loading || !templateName"
                  @click="saveTemplate()">
                  <span v-if="loading">
                    <div class="spinner-grow spinner-grow-sm" role="status"></div>
                  </span>
                  <span v-else>
                    {{$t('button.ok')}}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap'

export default {
  props: {
    closeModal: { type: Function }
  },
  data () {
    return {
      loading: false,
      templateName: '',
      mySaveTemplateModal: null
    }
  },
  mounted() {
    // Close side cart
    if (this.$store.getters['NavigationDrawer/CART_OPEN']) {
      this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
    }
    this.mySaveTemplateModal = new Modal(this.$refs.mySaveTemplateModal)
    this.show()
  },
  methods: {
    show () {
      this.mySaveTemplateModal.show()
    },
    hide () {
      this.mySaveTemplateModal.hide()
    },
    saveTemplate () {
      let vue = this
      vue.loading = true
      vue.$store.dispatch('Order/ADD_ORDER_TEMPLATE', vue.templateName)
        .then(function () {
          vue.$store.dispatch('Cart/REMOVE_CART')
          vue.loading = false
          vue.$router.push('/order/templates')
        })
        .finally(() => {
          this.hide()
        })
    }
  }
}
</script>
