export default {
  methods: {
    toggleCart () {
      this.$store.dispatch('NavigationDrawer/TOGGLE_CART')
    },
    parseDateToString (date) {
      let day = this.$moment(date).format('dddd');
      let dayString = day.charAt(0).toUpperCase() + day.slice(1);
      return  dayString + ' ' + this.$moment(date).format('D.M.YYYY');
    },
    orderItems () {
      this.$store.commit('Order/DISABLE_ORDER_ERROR');
      const smallDeliveryFee = this.$store.getters['Catalog/GET_SMALL_DELIVERY_FEE_PRODUCT']
      const sdfInCart = this.$store.getters['Cart/GET_ITEMS_IN_CART'].find(item => item.product_id === "P9996")
      // Add small delivery fee if it exists for user and if it's not yet in cart
      if (this.orderAmountLessThanMinimum && smallDeliveryFee && !sdfInCart) {
        this.$store.dispatch('Cart/ADD_ITEM', { amount: 1, price_id: smallDeliveryFee.price_id, product_id: smallDeliveryFee.id })
      }
      // Remove small delivery fee from cart if the total amount exceeds the minimum
      if (!this.orderAmountLessThanMinimum && sdfInCart) {
        this.$store.dispatch('Cart/REMOVE_ITEM', sdfInCart)
      }
      this.$store.dispatch('Order/ORDER_ITEMS', this.sortedCart)
        .then(response => {
          this.$router.push({name: response.name, params: { order: response.order }})
        })
    }
  },
  computed: {
    cartTotals () {
      let totals = {
        withVat: 0,
        withOutVat: 0
      };
      
      this.sortedCart.forEach(sortedGroup => {
        sortedGroup.items.forEach(item => {
          
          // count item totals
          totals.withVat += parseFloat(item.price_incl_tax) * item.amount;
          totals.withOutVat += parseFloat(item.price_excl_tax) * item.amount;
          
          // if item has some additional items included
          if(item.additional_items) {
            totals.withVat += item.additional_items.reduce((total, add) => parseFloat(total) + parseFloat(add.unit_price_incl_tax), 0) * parseInt(item.amount);
            totals.withOutVat += item.additional_items.reduce((total, add) => parseFloat(total) + parseFloat(add.unit_price_excl_tax), 0) * parseInt(item.amount)
          }
        });
        
        // if there is some auto add products
        if(sortedGroup.auto_add_products.length > 0) {
          totals.withVat += sortedGroup.auto_add_products.reduce((total, add) => parseFloat(total) + parseFloat(add.price_incl_tax) * parseInt(add.amount), 0);
          totals.withOutVat += sortedGroup.auto_add_products.reduce((total, add) => parseFloat(total) + parseFloat(add.price_excl_tax) * parseInt(add.amount), 0)
        }
        
      });
      
      return totals
    },
    isEmptyCart () {
      return this.$store.getters['Cart/EMPTY_CART'];
    },
    cart () {
      return this.$store.getters['Cart/GET_CART'];
    },
    sortedCart () {
      return this.$store.getters['Cart/GET_SORTED_CART'];
    },
    extraFees () {
      return this.$store.getters['Cart/GET_EXTRA_FEES'];
    },
    catalog () {
      return this.$store.getters['Catalog/GET_CATALOG'];
    },
    products () {
      return this.$store.getters['Catalog/GET_PRODUCTS']
    },
    itemsInCart () {
      return this.$store.getters['Cart/ITEMS_IN_CART']
    },
    shoppingCartHeader () {
      return this.$t('cart.cart')+` (${this.itemsInCart} ${this.itemsInCart === 1 ?  this.$t('cart.item') : this.$t('cart.items')})`
    },
    continueOrderText () {
      if (this.$route.path === '/shop/categories') {
        return 'cart.continue_order'
      } else {
        return 'cart.close'
      }
    },
    cartUpdating () {
      return this.$store.getters['Cart/CART_UPDATING']
    },
    removeItem () {
      return this.$store.getters['Modal/REMOVE_ITEM_STATUS']
    },
    confirmOrderModal () {
      return this.$store.getters['Modal/CONFIRM_ORDER']
    },
    minimumOrderAmount () {
      return this.$store.getters['Auth/GET_MINIMUM_ORDER_AMOUNT']
    },
    orderAmountLessThanMinimum () {
      return this.cartTotals.withOutVat < this.minimumOrderAmount
    }
  },
  watch: {
    removeItem: function (value) {
      if (value) {
        this.$refs['remove-item'].show();
      } else {
        this.$refs['remove-item'].hide();
      }
    }
  }
}
