<template>
  <div ref="myRemoveModal" class="modal" id="modal-center" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="row">
            <div class="col-12 mb-1">
              <strong>{{$t('modal.remove_item')}}?</strong>
              <p v-if="item" class="text-muted mt-2">{{item.description}}</p>
            </div>
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-secondary confirm-button"
                  @click="hide()">{{$t('button.cancel')}}</button>
                <button type="button" class="btn btn-primary confirm-button ms-2 remove-item"
                  @click="removeItem()">{{$t('button.ok')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from 'bootstrap';
    export default {
      props: {
        closeModal: {
          type: Function
        }
      },
        computed: {
            item () {
                return this.$store.getters['Cart/GET_REMOVE_ITEM']
            }
        },
        mounted() {
        // Create a new Modal instance with the ref from the template
        this.myRemoveModal = new Modal(this.$refs.myRemoveModal);
        this.myRemoveModal.show();
      },
        methods: {
            hide () {
                this.$store.commit('Modal/DISABLE_REMOVE_ITEM');
                this.myRemoveModal.hide();
                this.closeModal();
            },
            show () {
              this.myRemoveModal.show();
            },
            removeItem () {
                this.$store.dispatch('Cart/REMOVE_ITEM', this.item);
                this.myRemoveModal.hide();
            }
        }
    }
</script>

<style scoped></style>
