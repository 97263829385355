<template>
    <div>
        <div class="list-group-item" v-for="(category, i) in catalog.categories" :key="i"
            :class="['own-list', 'p-0', 'border-start-0', 'border-end-0', {selected: categoryChecked(category)}]">
            <span v-if="category.code !== 'RAHTI'">
                <div class="category-list-title">
                    <div class="d-flex justify-content-between align-items-center px-2 w-100 category-item"
                        @click="toggleCategory(i)">
                        <div>
                            <span class="fa-stack">
                                <i class="fa-regular fa-circle fa-stack-1x" style="font-size: 1.7rem"></i>
                                <i class="fa fa-check fa-stack-1x text-success"></i>
                            </span> {{ parseDescriptionLocale(category) }}
                        </div>
                        <div v-if="openCategories[i]">
                            <span class="fa-pull-right pe-3 text-primary"></span>
                        </div>
                        <div v-else>
                            <span class="fa-pull-right pe-3 text-primary"></span>
                        </div>
                    </div>
                </div>
                <transition name="slide">
                    <div v-if="openCategories[i]">
                        <hr class="w-100 my-0">
                        <div v-for="(product, index) in category.products" :key="product.id"
                            :class="['item-container', {'out-of-stock': product.out_of_stock }]">
                            <div class="row item item-name px-3 g-0" @click="selectItem(product)">
                                <div class="col-10 ps-1">
                                    <span class="description">{{ parseDescriptionLocale(product) }},
                                        {{product.unit_of_measure}}</span>
                                    <span v-if="product.notes">
                                        <i class="fa-solid fa-info-circle text-danger"></i>
                                    </span>
                                </div>
                                <div class="col-2 align-items-center product-basket-container">
                                    <div align-items-center v-if="amountInCart(product.price_id) > 0"
                                        class="product-amount-cart-container text-center">
                                        <span class="product-amount-cart">{{amountInCart(product.price_id)}}</span>
                                    </div>
                                    <i align-items-center
                                        class="show-daily-data icon-l fa-solid fa-shopping-basket fa-pull-right text-muted pe-1"></i>
                                </div>
                            </div>
                            <hr v-if="index +1 < category.products.length" class="w-100 my-0">
                        </div>
                    </div>
                </transition>
            </span>
        </div>
        <!-- SELECTED ITEM MODAL -->
        <selected-item v-if="selectedItem" />
    </div>
</template>


<script>
import SelectedItem from "../modals/SelectedItem"
export default {
    props: ['catalog'],
    components: { SelectedItem },
    data() {
        return {
            openCategories: {}
        }
    },
    computed: {
        isDesktop() {
            return document.documentElement.clientWidth > 767
        },
        selectedItem() {
            return this.$store.getters['Catalog/GET_SELECTED_ITEM']
        }
    },
    methods: {
        categoryChecked(category) {
            let cart = this.$store.getters['Cart/GET_SORTED_CART']
            if (!cart.length || !category.products.length) {
                return false
            }
            return !!cart[0].items.filter(cartProduct => category.products.some(product => product.id === cartProduct.product_id)).length
        },
        toggleCategory(i) {
            this.$set(this.openCategories, i, !this.openCategories[i])
        },
        selectItem(item) {
            this.$store.commit('Catalog/SET_SELECTED_ITEM', item)
        },
        categoryId(id) {
            return 'category-' + id
        },
        amountInCart(price_id) {
            let sortedCart = this.$store.getters['Cart/GET_SORTED_CART']
            let amount = 0
            // loop cart items and check if item is in cart and get amount to show in category list
            sortedCart.forEach(sortedGroup => {
                sortedGroup.items.forEach(item => {
                    if (item.amount > 0 && item.price_id === price_id) {
                        amount = item.amount
                    }
                })
            })
            return amount
        },
        parseDescriptionLocale(desc) {
            const localizedField = `description_${this.$i18n.locale}`
            return desc[localizedField]
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';

.own-list {
    &:first-child {
        border-top: none;
    }

    .category-item {
        cursor: pointer;
        min-height: 3rem;
        font-family: 'Noir Pro Medium';

        &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, .03);
            transition: .2s;
        }

        .fa-check {
            display: none;
        }
    }

    .out-of-stock {
        .item {
            .description {
                text-decoration: line-through;
            }
        }
    }

    .item {
        cursor: pointer;
        padding: 10px 20px;

        &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, .03);
            transition: .2s;
        }

        .icon-l {
            font-size: $icon-size-l;
        }
    }

    .data {
        padding: 5px 20px;
        font-size: 70%;
        background: $panel-background;

        svg {
            color: $foodstock-light-gray;
            margin-right: 3px;

            &.toggle-data {
                margin-left: 3px;
            }
        }
    }

    .product-modal-header {
        padding-left: 1rem;
    }

    .product-basket-container {
        position: relative;
    }

    .product-amount-cart-container {
        position: absolute;
        height: 1rem;
        min-width: 1rem;
        right: -5px;
        top: -7px;
        background-color: #99cd7c;
        border-radius: .7rem;
        line-height: 15px;
        padding-left: 0.2rem;
        padding-right: 0.2rem;

        .product-amount-cart {
            color: white;
            font-size: .7rem;
            font-weight: bold;
        }
    }

    .product-info {
        font-size: .9rem;
        line-height: .9rem;
        font-weight: bold;
    }

    &.selected {
        .category-item {
            .fa-check {
                display: block;
            }
        }
    }
}
</style>
